import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionRevenuePlans: [],
    totalRows: 0,
    statuses: STATUSES,
  },
  getters: {
    admissionRevenuePlans: state => state.admissionRevenuePlans,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_ADMISSION_REVENUE_PLANS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionRevenuePlans = records
    },
  },
  actions: {
    async readAdmissionRevenuePlans({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_revenue_plan', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_REVENUE_PLANS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionRevenuePlans({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_revenue_plan', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionRevenuePlans({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_revenue_plan/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionRevenuePlans({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_revenue_plan/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
