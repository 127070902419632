import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentAllowances: [],
    totalRows: 0,
    allStudent: [],
    allAllowance: [],
  },
  getters: {
    studentAllowances: state => state.studentAllowances,
    totalRows: state => state.totalRows,
    allStudent: state => state.allStudent,
    allAllowance: state => state.allAllowance,
  },
  mutations: {
    SET_STUDENT_ALLOWANCES: (state, { total, records }) => {
      state.totalRows = total
      state.studentAllowances = records
    },

    SET_ALL: (state, { allStudent, allAllowance }) => {
      state.allStudent = allStudent
      state.allAllowance = allAllowance
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseStudent = await axios.get('uni/studentAllowances/students')
        const responseAllowance = await axios.get('uni/studentAllowances/allowances')

        if (responseStudent.data.code === ApiCode.SUCCESS && responseAllowance.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allStudent: responseStudent.data.data.students, allAllowance: responseAllowance.data.data.allowances })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentAllowances({ commit }, params) {
      try {
        const response = await axios.get('uni/studentAllowances', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENT_ALLOWANCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentAllowance({ commit }, params) {
      try {
        const response = await axios.post('uni/studentAllowances', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentAllowance({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentAllowances/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentAllowance({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentAllowances/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
