import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionRevenueSchedule: [],
    totalRows: 0,
    statuses: STATUSES,
  },
  getters: {
    admissionRevenueSchedule: state => state.admissionRevenueSchedule,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_ADMISSION_REVENUE_SCHEDULE: (state, { total, records }) => {
      state.totalRows = total
      state.admissionRevenueSchedule = records
    },
  },
  actions: {
    async readAdmissionRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.get('admissions/revenue_schedule', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_REVENUE_SCHEDULE', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.post('admissions/revenue_schedule', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.put(`admissions/revenue_schedule/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionRevenueSchedule({ commit }, id) {
      try {
        const response = await axios.delete(`admissions/revenue_schedule/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
