import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    maxOrderNo: 0,
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_LISTS: (state, data) => { state.dataLists = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, data) => { state.maxOrderNo = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/scoringFormulas', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/scoringFormulas', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/scoringFormulas/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/scoringFormulas/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('uni/scoringFormulas/maxOrderNo', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAX_ORDER_NO', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
