import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/fact_scientific_topics',
    name: 'factScientificTopics',
    component: () => import('@/views/research/fact_scientific_topics/index.vue'),
    meta: {
      pageTitle: 'Loại đề tài',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Loại đề tài',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_SCIENTIFIC_TOPIC,
    },
  },
  {
    path: '/fact_topic_levels',
    name: 'factTopicLevels',
    component: () => import('@/views/research/fact_topic_levels/index.vue'),
    meta: {
      pageTitle: 'Cấp độ đề tài',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Cấp độ đề tài',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_TOPIC_LEVEL,
    },
  },
  {
    path: '/fact_research_fields',
    name: 'fact_research_fields',
    component: () => import('@/views/research/fact_research_fields/index.vue'),
    meta: {
      pageTitle: 'Lĩnh vực đề tài',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Lĩnh vực đề tài',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RESEARCH_FIELD,
    },
  },
  {
    path: '/research',
    name: 'research',
    component: () => import('@/views/research/index.vue'),
    meta: {
      pageTitle: 'Quản lý đề tài nghiên cứu',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Quản lý đề tài nghiên cứu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH,
    },
  },

  {
    path: '/fact_research_roles',
    name: 'fact_research_roles',
    component: () => import('@/views/research/fact_research_roles/index.vue'),
    meta: {
      pageTitle: 'Vai trò',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Vai trò',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RESEARCH_ROLE,
    },
  },
  {
    path: '/research/reviewBoard',
    name: 'researchReviewBoard',
    component: () => import('@/views/research/review-board/ReviewBoard.vue'),
    meta: {
      pageTitle: 'Hội đồng thẩm định',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Hội đồng thẩm định',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_REVIEW_BOARD,
    },
  },
  {
    path: '/research/researchInnovationReviewCouncils',
    name: 'researchInnovationReviewCouncils',
    component: () => import('@/views/research/research-innovation-review-council/ResearchInnovationReviewCouncil.vue'),
    meta: {
      pageTitle: 'Hội đồng nghiệm thu sáng kiến',
      breadcrumb: [
        {
          text: 'Nghiệm thu sáng kiến',
          active: false,
        },
        {
          text: 'Hội đồng nghiệm thu sáng kiến',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_INNOVATION_REVIEW_COUNCILS,
    },
  },
  {
    path: '/research/approvalResearch',
    name: 'approvalResearch',
    component: () => import('@/views/research/approval-research/ApprovalResearch.vue'),
    meta: {
      pageTitle: 'Phê duyệt đề tài',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Phê duyệt đề tài',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.APPROVAL_RESEARCH,
    },
  },
  {
    path: '/research/invention',
    name: 'researchInvention',
    component: () => import('@/views/research/invention/Invention.vue'),
    meta: {
      pageTitle: 'Sáng chế / giải pháp',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Sáng chế / giải pháp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_INVENTION,
    },
  },
  {
    path: '/research/techTransfer',
    name: 'researchTechTransfer',
    component: () => import('@/views/research/tech-transfer/TechTransfer.vue'),
    meta: {
      pageTitle: 'Chuyển giao công nghệ',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Chuyển giao công nghệ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_TECH_TRANSFER,
    },
  },
  {
    path: '/research/student',
    name: 'researchStudent',
    component: () => import('@/views/research/student/ResearchStudent.vue'),
    meta: {
      pageTitle: 'Nghiên cứu khoa học của người học',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Nghiên cứu khoa học của người học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_STUDENT,
    },
  },
  {
    path: '/research/article',
    name: 'researchArticle',
    component: () => import('@/views/research/article/ResearchArticle.vue'),
    meta: {
      pageTitle: 'Bài báo khoa học',
      breadcrumb: [
        {
          text: 'Nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Bài báo khoa học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESEARCH_ARTICLE,
    },
  },
  {
    path: '/factResearchArticleRole',
    name: 'factResearchArticleRole',
    component: () => import('@/views/research/fact-research-article-role/Index.vue'),
    meta: {
      pageTitle: 'Vai trò',
      breadcrumb: [
        {
          text: 'Bài báo khoa học',
          active: false,
        },
        {
          text: 'Vai trò',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RESEARCH_ARTICLE_ROLE,
    },
  },
]
