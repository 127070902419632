import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    totalRows: 0,
    qrEmployeeStudents: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    qrEmployeeStudents: state => state.qrEmployeeStudents,
  },
  mutations: {
    SET_TOTAL_ROW: (state, data) => {
      state.totalRows = data
    },
    SET_QR_EMPLOYEE_STUDENTS: (state, data) => {
      state.qrEmployeeStudents = data
    },
  },
  actions: {
    async getQrEmployeeStudents({ commit }, params) {
      try {
        const response = await axios.get('uni/quantityReports/getQrEmployeeStudents', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TOTAL_ROW', total)
          commit('SET_QR_EMPLOYEE_STUDENTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
