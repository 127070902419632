import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    admissionCommitteeEmployees: [],
    employees: [],
  },
  getters: {
    admissionCommitteeEmployees: state => state.admissionCommitteeEmployees,
    employees: state => state.employees,
  },
  mutations: {
    SET_ADMISSION_COMMITTEE_EMPLOYEES: (state, data) => { state.admissionCommitteeEmployees = data },
    SET_EMPLOYEES: (state, data) => { state.employees = data },
  },
  actions: {
    async getAdmissionCommitteeEmployee({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_committee_employees', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ADMISSION_COMMITTEE_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getEmployeesByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('/uni/employees/getAllByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionCommitteeEmployee({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_committee_employees', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionCommitteeEmployee({ commit }, param) {
      try {
        const response = await axios.put(`/admissions/admission_committee_employees/${param.id}`, param)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionCommitteeEmployee({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_committee_employees/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
