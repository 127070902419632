import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export const baseAttr = Object.freeze({
    basePath: ApiExtRoutes.employeeEvaluate,
})

export default {
    namespaced: true,
    state: {
        employeeEvaluate: [],
    },
    getters: {
        employeeEvaluate: state => state.employeeEvaluate,
    },
    mutations: {
        EMPLOYEE_EVALUATE: (state, employeeEvaluate) => {
            state.employeeEvaluate = employeeEvaluate
        },
    },
    actions: {
        async getEmployeeEvaluate({ commit }, params) {
            try {
                const response = await axios.get(`surveys/getDataEvaluate`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('EMPLOYEE_EVALUATE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createEmployeeEvaluate({ commit }, params) {
            try {
                const response = await axios.post(`surveys/createEvaluate`, params)
                const { data } = response;
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateEmployeeEvaluate({ commit }, params) {
            try {
                const response = await axios.put(`surveys/updateEvaluate/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
