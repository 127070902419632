import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    teacherSubjects: [],
    totalRows: 0,
    statuses: [],
  },
  getters: {
    teacherSubjects: state => state.teacherSubjects,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_TEACHER_SUBJECTS: (state, { total, records }) => {
      state.totalRows = total
      state.teacherSubjects = records
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/teacherSubjects/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses } = data.data
          commit('SET_STATUSES', statuses)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readTeacherSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/teacherSubjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TEACHER_SUBJECTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeacherSubjects({ commit }, params) {
      try {
        const response = await axios.post('uni/teacherSubjects', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTeacherSubjects({ commit }, params) {
      try {
        const response = await axios.put(`uni/teacherSubjects/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateListTeacherSubjects({ commit }, params) {
      try {
        const response = await axios.put(`uni/teacherSubjects/updateList/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTeacherSubjects({ commit }, id) {
      try {
        const response = await axios.delete(`uni/teacherSubjects/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
