import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    classes: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    classes: state => state.classes,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_COURSES: (state, records) => {
      state.courses = records
    },
    SET_CLASSES: (state, records) => {
      state.classes = records
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async insertStudent({ commit }, params) {
      try {
        const response = await axios.post('uni/students/insertStudent',  params )
        const { data } = response
        return {
          code: data.code,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystems({ commit }) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/classes/getByCourseId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CLASSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
