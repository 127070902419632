import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    researches: [],
    categories: [],
    fields: [],
    levels: [],
  },
  getters: {
    researches: state => state.researches,
    categories: state => state.categories,
    fields: state => state.fields,
    levels: state => state.levels,
  },
  mutations: {
    SET_RESEARCHES: (state, data) => { state.researches = data },
    SET_CATEGORIES: (state, data) => { state.categories = data },
    SET_FIELDS: (state, data) => { state.fields = data },
    SET_LEVELS: (state, data) => { state.levels = data },
  },
  actions: {
    async getAllCategories({ commit }) {
      try {
        const response = await axios.get('uni/fact_scientific_topics/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CATEGORIES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllFields({ commit }) {
      try {
        const response = await axios.get('uni/fact_research_fields/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FIELDS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAllLevels({ commit }) {
      try {
        const response = await axios.get('uni/fact_topic_levels/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_LEVELS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getResearchesNeedApproved({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/getResearchesNeedApproved', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCHES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async approvedResearch({ commit }, { id, params }) {
      try {
        const response = await axios.put(`uni/researches/${id}/approvedResearch`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
