import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import {
  POLITIC_ADMISSION_CONFIRM,
} from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    dataListsAddClass: [],
    totalRowsAddClass: 0,
    statuses: POLITIC_ADMISSION_CONFIRM,
    resourceName: {
      fullName: 'Xác nhận thí sinh đủ điều kiện',
      shortName: 'Xác nhận thí sinh',
    },
    dataExport: null,
    allFactEthnics: [],
    dataSources: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    dataListsAddClass: state => state.dataListsAddClass,
    totalRows: state => state.totalRows,
    totalRowsAddClass: state => state.totalRowsAddClass,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    dataExport: state => state.dataExport,
    allFactEthnics: state => state.allFactEthnics,
    dataSources: state => state.dataSources,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_LISTS_ADD_CLASS: (state, { total, records }) => {
      state.totalRowsAddClass = total
      state.dataListsAddClass = records
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
    SET_FACT_ETHNICS: (state, data) => {
      state.allFactEthnics = data
    },
    SET_DATA_GENDER_SOURCES: (state, data) => { state.dataSources = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/applicant_students/getAllPoliticAdmission', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataAddClass({ commit }, params) {
      try {
        const response = await axios.get('/admissions/applicant_students/getAllPoliticAdmissionAddClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS_ADD_CLASS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    
    async updateData({ commit }, params) {
      try {
        const response = await axios.put('/admissions/applicant_students/updatePoliticAdmissionStatus', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDataInfo({ commit }, params) {
      try {
        const response = await axios.put('/admissions/applicant_students/updatePoliticAdmissionInfo', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getFactEthnics({ commit }) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactEthnics')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FACT_ETHNICS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/factGenders', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_DATA_GENDER_SOURCES', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExport({ commit }, params) {
      try {
        const responseLearningResult = await axios({
          method: 'get',
          url: 'admissions/applicant_students/exportPoliticAdmission',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseLearningResult })
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
