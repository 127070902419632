import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/report/teachingAndExamCondition',
    name: 'reportTeachingAndExamCondition',
    component: () => import('@/views/report/teaching-exam-condition/TeachingExamCondition.vue'),
    meta: {
      pageTitle: 'Kết quả giảng dạy và điều kiện thi theo học phần',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Kết quả giảng dạy và điều kiện thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_TEACHING_EXAM_CONDITION,
    },
  },
  {
    path: '/report/totalLectureHoursPerCourse',
    name: 'totalLectureHoursPerCourse',
    component: () => import('@/views/report/total-lecture-hours-per-course/index.vue'),
    meta: {
      pageTitle: 'Tổng số tiết học giảng dạy của giảng viên',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Tổng số tiết học giảng dạy của giảng viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TOTAL_LECTURE_HOURS_PER_COURSE,
    },
  },
  {
    path: '/report/countStudentRegisterSubject',
    name: 'countStudentRegisterSubject',
    component: () => import('@/views/report/report-student-register-subject/Index.vue'),
    meta: {
      pageTitle: 'Thống kê học viên đăng ký tín chỉ',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Thống kê học viên đăng ký tín chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_COUNT_STUDENT_REGISTER_SUBJECT,
    },
  },
  {
    path: '/report/reportStudentCancelSubject',
    name: 'reportStudentCancelSubject',
    component: () => import('@/views/report/student-cancel-subject/Index.vue'),
    meta: {
      pageTitle: 'Chi tiết học viên hủy đăng ký học phần',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Chi tiết học viên hủy đăng ký học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_STUDENT_CANCEL_SUBJECT,
    },
  },
  {
    path: '/report/point-by-subject',
    name: 'reportPointByCreditClass',
    component: () => import('@/views/report/examination/PointByCreditClassReport.vue'),
    meta: {
      pageTitle: 'Báo cáo tổng kết học phần',
      breadcrumb: [
        {
          text: 'Báo cáo thống kê',
          active: false,
        },
        {
          text: 'Báo cáo tổng kết học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_POINT_BY_CREDIT_CLASS,
    },
  },
  {
    path: '/report/avg-student-class',
    name: 'reportAvgStudentClass',
    component: () => import('@/views/report/avg-student-class/AvgStudentClass.vue'),
    meta: {
      pageTitle: 'Bảng điểm tổng hợp kỳ theo lớp/chuyên ngành',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Bảng điểm tổng hợp kỳ theo lớp/chuyên ngành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_AVG_STUDENT_CLASS,
    },
  },
  {
    path: '/report/class-receipts-course-semester',
    name: 'reportClassReceiptsCourseSemester',
    component: () => import('@/views/report/class-receipts-course-semester/ClassReceiptsCourseSemester.vue'),
    meta: {
      pageTitle: 'Báo cáo tình hình nộp học phí của học viên theo học kỳ',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Báo cáo tình hình nộp học phí của học viên theo học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_CLASS_RECEIPTS_COURSE_SEMESTER,
    },
  },
  {
    path: '/report/paymentStatusByCourseSemester',
    name: 'reportPaymentStatusByCourseSemester',
    component: () => import('@/views/report/payment-status-by-course-semester/PaymentStatusByCourseSemester.vue'),
    meta: {
      pageTitle: 'Báo cáo',
      breadcrumb: [
        {
          text: 'Tình hình nộp học phí của học viên theo học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_PAYMENT_STATUS_BY_COURSE_SEMESTER,
    },
  },
  {
    path: '/report/credit-class-student-point',
    name: 'reportCreditClassStudentPoint',
    component: () => import('@/views/report/credit-class-student-point/CreditClassStudentPoint.vue'),
    meta: {
      pageTitle: 'Bảng điểm tổng hợp đánh giá học phần',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Bảng điểm tổng hợp đánh giá học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_CREDIT_CLASS_STUDENT_POINT,
    },
  },
  {
    path: '/report/teacher-specialization-assignment',
    name: 'reportTeacherSpecializationAssignment',
    component: () => import('@/views/report/teacher-specialization-assignment/TeacherSpecializationAssignment.vue'),
    meta: {
      pageTitle: 'Phân công chuyên môn năm học',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Phân công chuyên môn năm học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_TEACHER_SPECIALIZATION_ASSIGNMENT,
    },
  },
  {
    path: '/report/class-learning-alert',
    name: 'reportLearningAlert',
    component: () => import('@/views/report/class-learning-alert/ClassLearningAlert.vue'),
    meta: {
      pageTitle: 'Báo cáo cảnh báo học tập theo lớp cố định',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Báo cáo cảnh báo học tập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_CLASS_LEARNING_ALERT,
    },
  },
  {
    path: '/report/reportCreditClassBySubject',
    name: 'reportCreditClassBySubject',
    component: () => import('@/views/report/credit-class-by-subject/ReportCreditClassBySubject.vue'),
    meta: {
      pageTitle: 'Báo cáo phổ điểm tổng kết theo học phần',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Phổ điểm tổng kết học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_CREDIT_CLASS_BY_SUBJECT,
    },
  },
  {
    path: '/report/retakeExamSecondTime',
    name: 'retakeExamSecondTime',
    component: () => import('@/views/report/retake-exam-second-time/RetakeExamSecondTime.vue'),
    meta: {
      pageTitle: 'Báo cáo danh sách học viên thi lại lần hai',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Báo cáo danh sách học viên thi lại lần hai',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_RETAKE_EXAM_SECOND_TIME,
    },
  },
  {
    path: '/report/studentBeRepeated',
    name: 'studentBeRepeated',
    component: () => import('@/views/report/student-be-repeated/StudentBeRepeated.vue'),
    meta: {
      pageTitle: 'Báo cáo danh sách học viên học lại',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Báo cáo danh sách học viên học lại',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_STUDENT_BE_REPEATED,
    },
  },
  {
    path: '/report/semesterExam',
    name: 'reportSemesterExam',
    component: () => import('@/views/report/semester-exam/SemesterExam.vue'),
    meta: {
      pageTitle: 'Báo cáo lịch thi',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Báo cáo lịch thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_SEMESTER_EXAM,
    },
  },
  {
    path: '/report/studentRegisteredSubject',
    name: 'studentRegisteredSubject',
    component: () => import('@/views/report/students-registered-by-subject/index.vue'),
    meta: {
      pageTitle: 'Thông kê số lượng học viên đăng ký theo môn',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Thông kê số lượng học viên đăng ký theo môn',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_STUDENTS_REGISTERED_BY_SUBJECT,
    },
  },
  {
    path: '/report/certification',
    name: 'reportCertification',
    component: () => import('@/views/report/certification/Certification.vue'),
    meta: {
      pageTitle: 'Thống kê chứng chỉ',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Thống kê chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_CERTIFICATION,
    },
  },
  {
    path: '/manageReport',
    name: 'manageReport',
    component: () => import('@/views/report/manage/Report.vue'),
    meta: {
      pageTitle: 'Quản lý báo cáo',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Quản lý báo cáo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT,
    },
  },
  {
    path: '/report/payments',
    name: 'reportPayments',
    component: () => import('@/views/report/payment/Index.vue'),
    meta: {
      pageTitle: 'Báo cáo khoản thu',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Báo cáo khoản thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PAYMENT,
    },
  },
  {
    path: '/report/countStudentCancelSubject',
    name: 'countStudentCancelSubject',
    component: () => import('@/views/report/count-student-cancel-subject/Index.vue'),
    meta: {
      pageTitle: 'Số lượng học viên hủy đăng ký học phần',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Số lượng học viên hủy đăng ký học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_COUNT_STUDENT_CANCEL_SUBJECT,
    },
  },
  {
    path: '/report/graduationConditionScoreClass',
    name: 'reportGraduationConditionScoreClass',
    component: () => import('@/views/report/graduation-condition-score-class/GraduationConditionScoreClass.vue'),
    meta: {
      pageTitle: 'Bảng điểm xét tốt nghiệp',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Bảng điểm xét tốt nghiệp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_GRADUATION_CONDITION_SCORE_CLASS,
    },
  },
  {
    path: '/report/courseScoreClass',
    name: 'reportCourseScoreClass',
    component: () => import('@/views/report/course-score-class/CourseScoreClass.vue'),
    meta: {
      pageTitle: 'Bảng điểm toàn khóa',
      breadcrumb: [
        {
          text: 'Báo cáo',
          active: false,
        },
        {
          text: 'Bảng điểm toàn khóa',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REPORT_COURSE_SCORE_CLASS,
    },
  },
]
