import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    accounts: [],
    allAccounts: [],
    totalRows: 0,
    accountRoles: [],
    totalAccountRoleRows: 0,
    supervisors: [],
    totalSupervisor: 0,
  },
  getters: {
    accounts: state => state.accounts,
    allAccounts: state => state.allAccounts,
    totalRows: state => state.totalRows,
    accountRoles: state => state.accountRoles,
    supervisors: state => state.supervisors,
    totalSupervisor: state => state.totalSupervisor,
  },
  mutations: {
    SET_ACCOUNTS: (state, data) => {
      state.accounts = data
    },
    SET_ALL_ACCOUNTS: (state, data) => {
      state.allAccounts = data
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_ACCOUNT_ROLES: (state, data) => {
      state.accountRoles = data
    },
    SET_SUPERVISORS: (state, { total, records }) => {
      state.totalSupervisor = total
      state.supervisors = records
    },
  },
  actions: {
    async getAccounts({ commit }, params) {
      try {
        const response = await axios.get('uni/accounts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ACCOUNTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getAllAccounts({ commit }, params) {
      try {
        const response = await axios.get('uni/accounts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_ALL_ACCOUNTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getSupervisors({ commit }, params) {
      try {
        const response = await axios.get('uni/account/supervisor', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SUPERVISORS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAccount({ commit }, params) {
      try {
        const response = await axios.post('uni/accounts', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAccount({ commit }, params) {
      try {
        const response = await axios.put(`uni/accounts/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAccount({ commit }, id) {
      try {
        const response = await axios.delete(`uni/accounts/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetPassword({ commit }, params) {
      try {
        const response = await axios.put(`uni/accounts/${params.id}/resetPassword`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async changePassword({ commit }, params) {
      try {
        const response = await axios.put(`uni/accounts/${params.id}/changePassword`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAccountRoles({ commit }, id) {
      try {
        const response = await axios.get(`uni/accounts/${id}/roles`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ACCOUNT_ROLES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveAccountRoles({ commit }, { id, rolesAdded, rolesDeleted }) {
      try {
        const response = await axios.post(`uni/accounts/${id}/roles`, { rolesAdded, rolesDeleted })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getListAccount({ commit }) {
      try {
        const response = await axios.get('uni/accounts/list_account')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ACCOUNTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async unlinkAccount({ commit }, id) {
      try {
        const response = await axios.put(`uni/accounts/${id}/unlinkAccount`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async grantSupervisor({ commit }, params) {
      try {
        const response = await axios.put('uni/account/supervisor/grant', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async revokeSupervisor({ commit }, params) {
      try {
        const response = await axios.put('uni/account/supervisor/revoke', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
