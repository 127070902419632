import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    creditClasses: [],
    generalScores: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    creditClasses: state => state.creditClasses,
    generalScores: state => state.generalScores,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.dataLists = data
    },
    SET_CREDIT_CLASSES: (state, { data }) => {
      state.creditClasses = data
    },
    SET_GENERAL_SCORES: (state, data) => {
      state.generalScores = data
    },
  },
  actions: {
    async getCreditClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_classes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = data.data.records.map(item => ({ value: item.id, label: item.code }))
          commit('SET_CREDIT_CLASSES', {
            data: mapData,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCreditClassStudentPoint', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async exportPdf({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCreditClassStudentPointExportPdf', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getGeneralScoresByCreditClassId({ commit }, params) {
      try {
        const response = await axios.get('uni/generalScores/getByCreditClassId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({ key: item.code, label: item.name }))
          commit('SET_GENERAL_SCORES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
