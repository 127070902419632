import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    studentClass: [],
    dataCourseSemester: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    studentClass: state => state.studentClass,
    dataCourseSemester: state => state.dataCourseSemester,
  },
  mutations: {
    SET_LISTS: (state, { records }) => {
      state.dataLists = records
    },
    SET_STUDENT_CLASS: (state, { records }) => {
      state.studentClass = records
    },
    SET_COURSE_SEMESTER_CLASS: (state, { records }) => {
      state.dataCourseSemester = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/studentReserves/selectStudentReservesByClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_LISTS', {
            records: data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataStudentClass({ commit }, params) {
      try {
        const response = await axios.get('uni/studentReserves/selectStudentClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENT_CLASS', {
            records: data.data.records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataCourseSemesterClass({ commit }, params) {
      try {
        const response = await axios.get('uni/studentReserves/selectCourseSemesterClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSE_SEMESTER_CLASS', {
            records: data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentReserve({ commit }, params) {
      try {
        const response = await axios.post('uni/studentReserves', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async createStudentReserveV1({ commit }, params) {
      try {
        const response = await axios.post('uni/studentReserves/create', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteStudentReserve({ commit }, params) {
      try {
        const response = await axios.put('uni/studentReserves/delete', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
