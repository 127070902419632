import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    buildings: [],
  },
  getters: {
    buildings: state => state.buildings,
  },
  mutations: {
    SET_BUILDINGS: (state, buildings) => { state.buildings = buildings },
  },
  actions: {
    async getBuilding({ commit }, data) {
      try {
        const response = await axios.get(`uni/buildings?currentPage=${data.currentPage}&itemsPerPage=${data.itemsPerPage}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { buildings } = data.data
          commit('SET_BUILDINGS', buildings)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createBuilding(dataObj) {
      try {
        const response = await axios.post('uni/buildings', dataObj)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteBuilding(id) {
      try {
        const response = await axios.post(`uni/buildings/${id}`)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
