import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.qbQuestions,
})
export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    subjects: [],
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    subjects: state => state.subjects,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message, data: data.data }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteMultiple({ commit }, params) {
      try {
        const response = await axios.put('uni/qbQuestions/deleteMultiple', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async importQuestions({ commit }, params) {
      try {
        const response = await axios.put('uni/qbQuestions/importQuestions', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          subjectTypeId: null,
          departmentId: '',
          programmeId: '',
          itemsPerPage: 1000,
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.subject, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          // const mapData = mapDropdown(records)
          const mapData = records.map(item => ({
            value: item.id,
            label: `${item.name} [${item.code}]`,
            shouldAddPracticeClass: item.shouldAddPracticeClass,
            code: item.code ? item.code : null,
          }))
          commit('SET_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
