import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    formulaAdmissionScoreCoefficients: [],
  },
  getters: {
    formulaAdmissionScoreCoefficients: state => state.formulaAdmissionScoreCoefficients,
  },
  mutations: {
    SET_FORMULA_ADMISSION_SCORE_COEFFICIENTS: (state, data) => { state.formulaAdmissionScoreCoefficients = data },
  },
  actions: {
    async getFormulaAdmissionScoreCoefficient({ commit }, params) {
      try {
        const response = await axios.get('/admissions/formula_admission_score_coefficient', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FORMULA_ADMISSION_SCORE_COEFFICIENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/formula_admission_score_coefficient', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, param) {
      try {
        const response = await axios.put(`/admissions/formula_admission_score_coefficient/${param.id}`, param)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/formula_admission_score_coefficient/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
