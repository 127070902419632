import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    total: 0,
    statuses: [],
    trainingForms: [],
    organizations: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    total: state => state.total,
    statuses: state => state.statuses,
    trainingForms: state => state.trainingForms,
    organizations: state => state.organizations,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, trainingSystems) => { state.trainingSystems = trainingSystems },
    SET_TRAINING_FORMS: (state, trainingForms) => { state.trainingForms = trainingForms },
    SET_ORGANIZATIONS: (state, organizations) => { state.organizations = organizations },
    SET_STATUS: (state, statuses) => { state.statuses = statuses },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {
    async getInit({ commit }) {
      try {
        const response = await axios.get('uni/trainingSystems/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { trainingForms } = data.data
          const { organizations } = data.data
          const { statuses } = data.data

          commit('SET_TRAINING_FORMS', trainingForms)
          commit('SET_ORGANIZATIONS', organizations)
          commit('SET_STATUS', statuses)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystems({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_TRAINING_SYSTEMS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTrainingSystem({ commit }, params) {
      try {
        const response = await axios.post('uni/trainingSystems', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTrainingSystem({ commit }, params) {
      try {
        const response = await axios.put(`uni/trainingSystems/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTrainingSystem({ commit }, id) {
      try {
        const response = await axios.delete(`uni/trainingSystems/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
