import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    totalRows: 0,
    researchReviewFormResult: [],
    comment: null,
    researchRole: [],
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    researchReviewFormResult: state => state.researchReviewFormResult,
    comment: state => state.comment,
    researchRole: state => state.researchRole,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_RESEARCH_REVIEW_FORM_RESULT: (state, data) => {
      state.researchReviewFormResult = data
    },
    SET_COMMENT: (state, comment) => {
      state.comment = comment
    },
    SET_RESEARCH_ROLE: (state, researchRole) => {
      state.researchRole = researchRole
    },
  },
  actions: {
    async readResearchReviewFormResult({ commit }, params) {
      try {
        const response = await axios.get('uni/research_review_present_evaluation', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_RESEARCH_REVIEW_FORM_RESULT', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readResearchReviewComment({ commit }, params) {
      try {
        const response = await axios.get('uni/research_review_present_comment', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COMMENT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateResearchReviewFormResult({ commit }, params) {
      try {
        const response = await axios.post('uni/research_review_present_evaluation', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateResearchReviewComment({ commit }, params) {
      try {
        const response = await axios.post('uni/research_review_present_comment', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getResearchRole({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_research_roles/getAllByType', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCH_ROLE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
