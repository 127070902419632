import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { RANK_CERTIFICATE_TYPES, STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionPriorityScores: [],
    totalRows: 0,
    statuses: STATUSES,
    rankCertificateTypes: RANK_CERTIFICATE_TYPES,
  },
  getters: {
    admissionPriorityScores: state => state.admissionPriorityScores,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    rankCertificateTypes: state => state.rankCertificateTypes,
  },
  mutations: {
    SET_ADMISSION_PRIORITY_SCORES: (state, { total, records }) => {
      state.totalRows = total
      state.admissionPriorityScores = records
    },
  },
  actions: {
    async readAdmissionPriorityScores({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_priority_score', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_PRIORITY_SCORES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionPriorityScores({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_priority_score', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionPriorityScores({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_priority_score/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionPriorityScores({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_priority_score/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async copyPriorityScore({ commit }, { id, params }) {
      try {
        const response = await axios.post(`admissions/admission_priority_score/copy/${id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
