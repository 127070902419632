import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    logActivities: [],
    total: 0,
  },
  getters: {
    logActivities: state => state.logActivities,
    total: state => state.total,
  },
  mutations: {
    SET_LOG_ACTIVITIES: (state, logActivities) => { state.logActivities = logActivities },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {
    async getLogActivities({ commit }, params) {
      try {
        const response = await axios.get('uni/logActivities', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_LOG_ACTIVITIES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
