import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    permissions: [],
    totalRows: 0,
  },
  getters: {
    permissions: state => state.permissions,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_PERMISSIONS: (state, { total, records }) => {
      state.totalRows = total
      state.permissions = records
    },
  },
  actions: {
    async getPermissions({ commit }, params) {
      try {
        const response = await axios.get('auth/permissions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PERMISSIONS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createPermission({ commit }, params) {
      try {
        const response = await axios.post('auth/permissions', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updatePermission({ commit }, params) {
      try {
        const response = await axios.put(`auth/permissions/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deletePermission({ commit }, id) {
      try {
        const response = await axios.delete(`auth/permissions/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
