import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentScholarships: [],
    totalRows: 0,
    allStudent: [],
    allScholarship: [],
    filterStudents: [],
  },
  getters: {
    studentScholarships: state => state.studentScholarships,
    totalRows: state => state.totalRows,
    allStudent: state => state.allStudent,
    allScholarship: state => state.allScholarship,
    filterStudents: state => state.filterStudents,
  },
  mutations: {
    SET_STUDENT_SCHOLARSHIPS: (state, { total, records }) => {
      state.totalRows = total
      state.studentScholarships = records
    },

    SET_FILTER_STUDENTS: (state, { total, records }) => {
      state.totalRows = total
      state.filterStudents = records
    },

    SET_ALL: (state, { allStudent, allScholarship }) => {
      state.allStudent = allStudent
      state.allScholarship = allScholarship
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseStudent = await axios.get('uni/studentScholarships/students')
        const responseScholarship = await axios.get('uni/studentScholarships/scholarships')

        if (responseStudent.data.code === ApiCode.SUCCESS && responseScholarship.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allStudent: responseStudent.data.data.students, allScholarship: responseScholarship.data.data.scholarships })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getByFilter({ commit }, params) {
      try {
        const response = await axios.get('uni/studentScholarships/getByFilter', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_FILTER_STUDENTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async themDsHocSinhNhanHocBong({ commit }, params) {
      try {
        const response = await axios.post('uni/studentScholarships/themDsHocSinhNhanHocBong', params )
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getStudentScholarships({ commit }, params) {
      try {
        const response = await axios.get('uni/studentScholarships', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENT_SCHOLARSHIPS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentScholarship({ commit }, params) {
      try {
        const response = await axios.post('uni/studentScholarships', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentScholarship({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentScholarships/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentScholarship({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentScholarships/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
