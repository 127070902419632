import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    articles: [],
    totalRows: 0,
    researches: [],
  },
  getters: {
    articles: state => state.articles,
    totalRows: state => state.totalRows,
    researches: state => state.researches,
  },
  mutations: {
    SET_RESEARCH_ARTICLES: (state, data) => { state.articles = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_RESEARCHES: (state, data) => { state.researches = data },
  },
  actions: {
    async createResearchArticle({ commit }, body) {
      try {
        const response = await axios.post('uni/researchArticles', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getResearchArticles({ commit }, params) {
      try {
        const response = await axios.get('uni/researchArticles', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_RESEARCH_ARTICLES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateResearchArticle({ commit }, body) {
      try {
        const response = await axios.put(`uni/researchArticles/${body.id}`, body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteResearchArticle({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchArticles/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAllResearchesByOrganization({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/getAllByOrganization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCHES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
