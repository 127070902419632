import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
    basePath: ApiExtRoutes.setOfCriteriaObjects,
})

export default {
    namespaced: true,
    state: {
        setOfCriteriaObjects: [],
        totalRows: 0,
        statuses: [],
        setOfCriterias: [],
        evaluateObjectGroups: [],
        evaluatedObjectGroups: [],
        dataResourecesExport: [],
    },
    getters: {
        setOfCriteriaObjects: state => state.setOfCriteriaObjects,
        totalRows: state => state.totalRows,
        statuses: state => state.statuses,
        setOfCriterias: state => state.setOfCriterias,
        evaluateObjectGroups: state => state.evaluateObjectGroups,
        evaluatedObjectGroups: state => state.evaluatedObjectGroups,
        dataResourecesExport: state => state.dataResourecesExport,
    },
    mutations: {
        SET_OF_CRITERIA_OBJECTS: (state, records) => {
            state.setOfCriteriaObjects = records
        },
        SET_TOTAL_ROWS: (state, total) => {
            state.totalRows = total
        },
        SET_STATUSES: (state, statuses) => {
            state.statuses = statuses
        },
        SET_OF_CRITERIAS: (state, setOfCriterias) => {
            state.setOfCriterias = setOfCriterias
        },
        SET_EVALUATE_OBJECT_GROUPS: (state, evaluateObjectGroups) => {
            state.evaluateObjectGroups = evaluateObjectGroups
        },
        SET_EVALUATED_OBJECT_GROUPS: (state, evaluatedObjectGroups) => {
            state.evaluatedObjectGroups = evaluatedObjectGroups
        },
        SET_DATA_SOURCES_EXPORT: (state, data) => { state.dataResourecesExport = data },
    },
    actions: {
        async begin({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/begin`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { setOfCriterias, evaluateObjectGroups, evaluatedObjectGroups } = data.data
                    commit('SET_OF_CRITERIAS', setOfCriterias)
                    commit('SET_EVALUATE_OBJECT_GROUPS', evaluateObjectGroups)
                    commit('SET_EVALUATED_OBJECT_GROUPS', evaluatedObjectGroups)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getData({ commit }, params) {
            try {
                const response = await axios.get(baseAttr.basePath, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_OF_CRITERIA_OBJECTS', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createData({ commit }, params) {
            try {
                const response = await axios.post(baseAttr.basePath, params)
                const { data } = response;
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateData({ commit }, params) {
            try {
                const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async deleteData({ commit }, id) {
            try {
                const response = await axios.delete(`${baseAttr.basePath}/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getDataSourcesExport({ commit }, params) {
            try {
              const response = await axios.get(`${baseAttr.basePath}/exportSetOfCriteriaObjects`, { params })
              const { data } = response
              if (data.code === ApiCode.SUCCESS) {
                commit('SET_DATA_SOURCES_EXPORT', data.data)
              }
            } catch (e) {
              handleCatch(e)
            }
        },
        async importSetOfCriteriaObjects({ commit }, params) {
        try {
            const response = await axios.post(`${baseAttr.basePath}/importSetOfCriteriaObjects`, { params })
            const { data } = response
            return data;
        } catch (e) {
            handleCatch(e)
        }
        },
    },
}
