import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/set-of-criteria-types',
    name: 'setOfCriteriaTypes',
    component: () => import('@/views/set-of-criteria-types/SetOfCriteriaTypes.vue'),
    meta: {
      pageTitle: 'Quản lý loại phiếu khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Danh sách phiếu khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SET_OF_CRITERIA_TYPES,
    },
  },
  {
    path: '/set-of-criterias',
    name: 'setOfCriterias',
    component: () => import('@/views/set-of-criterias/List.vue'),
    meta: {
      pageTitle: 'Quản lý phiếu khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Danh sách phiếu khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SET_OF_CRITERIAS,
    },
  },
  {
    path: '/evaluate_object_groups',
    name: 'evaluateObjectGroups',
    component: () => import('@/views/evaluate-object-groups/EvaluateObjectGroups.vue'),
    meta: {
      pageTitle: 'Quản lý các nhóm đối tượng thực hiện khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Danh sách nhóm đối tượng thực hiện khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EVALUATE_OBJECT_GROUPS,
    },
  },
  {
    path: '/evaluated_object_groups',
    name: 'evaluatedObjectGroups',
    component: () => import('@/views/evaluated-object-groups/EvaluatedObjectGroups.vue'),
    meta: {
      pageTitle: 'Quản lý các nhóm đối tượng được khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Danh sách nhóm đối tượng được khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EVALUATED_OBJECT_GROUPS,
    },
  },
  {
    path: '/set-of-criteria-objects',
    name: 'setOfCriteriaObjects',
    component: () => import('@/views/set-of-criteria-objects/List.vue'),
    meta: {
      pageTitle: 'Quản lý đợt khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Danh sách đợt khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SET_OF_CRITERIA_OBJECTS,
    },
  },
  {
    path: '/evaluate-management',
    name: 'evaluateManagement',
    component: () => import('@/views/evaluate-management/List.vue'),
    meta: {
      pageTitle: 'Báo cáo thống kê khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Báo cáo thống kê khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EVALUATE_MANAGEMENT,
    },
  },
  {
    path: '/set-of-evaluate-levels',
    name: 'setOfEvaluateLevels',
    component: () => import('@/views/set-of-evaluate-levels/List.vue'),
    meta: {
      pageTitle: 'Thang điểm đánh giá tổng quan',
      breadcrumb: [
        {
          text: 'Quản lý khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Thang điểm đánh giá tổng quan',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SET_OF_EVALUATE_LEVELS,
    },
  },
]
