import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    examinationRoomTeachers: [],
  },
  getters: {
    examinationRoomTeachers: state => state.examinationRoomTeachers,
  },
  mutations: {
    SET_EXAMINATION_ROOM_TEACHERS: (state, data) => { state.examinationRoomTeachers = data },
  },
  actions: {
    async getExaminationRoomTeachers({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/getExaminationRoomTeachers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EXAMINATION_ROOM_TEACHERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createExaminationRoomTeachers({ commit }, params) {
      try {
        const response = await axios.post('uni/examinationRooms/examinationRoomTeachers', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteExaminationRoomTeacher({ commit }, id) {
      try {
        const response = await axios.delete(`uni/examinationRooms/deleteExaminationRoomTeacher/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
