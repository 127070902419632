import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    criteriaSets: [],
    totalRows: 0,
    statuses: STATUSES,
    criteriaSetByType: [],
  },
  getters: {
    criteriaSets: state => state.criteriaSets,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    criteriaSetByType: state => state.criteriaSetByType,
  },
  mutations: {
    SET_CRITERIA_SETS: (state, { total, records }) => {
      state.totalRows = total
      state.criteriaSets = records
    },
    SET_CRITERIA_SET_BY_TYPE: (state, data) => { state.criteriaSetByType = data },
  },
  actions: {
    async readCriteriaSets({ commit }, params) {
      try {
        const response = await axios.get('/admissions/criteria_set', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CRITERIA_SETS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async copyCriteriaSet({ commit }, params) {
      try {
        const response = await axios.post(`admissions/criteria_set/copy/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCriteriaSets({ commit }, params) {
      try {
        const response = await axios.post('/admissions/criteria_set', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCriteriaSets({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/criteria_set/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCriteriaSets({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/criteria_set/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getListByTypeId({ commit }, params) {
      try {
        const response = await axios.get('admissions/criteria_set/getListByTypeId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CRITERIA_SET_BY_TYPE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
