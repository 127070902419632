import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    totalRows: 0,
    researches: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    researches: state => state.researches,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_RESEARCHES: (state, data) => { state.researches = data },
  },
  actions: {
    async createResearchStudent({ commit }, params) {
      try {
        const response = await axios.post('uni/researchStudents', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getResearchStudents({ commit }, params) {
      try {
        const response = await axios.get('uni/researchStudents', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_RESEARCHES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateResearchStudent({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchStudents/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteResearchStudent({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchStudents/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
