import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { SUSPEND_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.studentSuspend,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: SUSPEND_STATUSES,
    resourceName: {
      fullName: 'Xử lý cảnh báo thôi học',
      shortName: 'Cảnh báo',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
