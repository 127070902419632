import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    years: [],
    reviewBoards: [],
    totalRows: 0,
  },
  getters: {
    years: state => state.years,
    reviewBoards: state => state.reviewBoards,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_YEARS: (state, data) => { state.years = data },
    SET_REVIEW_BOARDS: (state, data) => { state.reviewBoards = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
  },
  actions: {
    async getYears({ commit }, params) {
      try {
        const response = await axios.get('uni/factYears/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getReviewBoards({ commit }, params) {
      try {
        const response = await axios.get('uni/researchReviewBoards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_REVIEW_BOARDS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createReviewBoard({ commit }, params) {
      try {
        const response = await axios.post('uni/researchReviewBoards', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateReviewBoard({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchReviewBoards/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteReviewBoard({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchReviewBoards/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
