import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    techTransfers: [],
    totalRows: 0,
    researches: [],
  },
  getters: {
    techTransfers: state => state.techTransfers,
    totalRows: state => state.totalRows,
    researches: state => state.researches,
  },
  mutations: {
    SET_TECH_TRANSFERS: (state, data) => { state.techTransfers = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_RESEARCHES: (state, data) => { state.researches = data },
  },
  actions: {
    async createTechTransfer({ commit }, params) {
      try {
        const response = await axios.post('uni/researchTechTransfers', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTechTransfers({ commit }, params) {
      try {
        const response = await axios.get('uni/researchTechTransfers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_TECH_TRANSFERS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateTechTransfer({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchTechTransfers/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTechTransfer({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchTechTransfers/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAllResearchesByOrganization({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/getAllByOrganization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCHES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
