import axios from '@/libs/axios'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataExport: null,
  },
  getters: {
    dataExport: state => state.dataExport,
  },
  mutations: {
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return,no-unused-vars
    async importStudents({ commit }, params) {
      try {
        const response = await axios.post('admissions/applicant_students/import', { params })
        const { data } = response
        return {
          code: data.code,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadTemplateImportAdmission({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'admissions/download_template/templateImportAdmission',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
