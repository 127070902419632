import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/qrReport/qrEmployeeStudent',
    name: 'qrEmployeeStudent',
    component: () => import('@/views/qr-report/QrEmployeeStudent.vue'),
    meta: {
      pageTitle: 'Báo cáo sản lượng cán bộ học viên',
      breadcrumb: [
        {
          text: 'Báo cáo sản lượng',
          active: false,
        },
        {
          text: 'Báo cáo sản lượng cán bộ học viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QR_EMPLOYEE_STUDENT,
    },
  },
]
