import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    listExemptions: [],
    totalRows: 0,
    allTrainingSystems: [],
    allMajors: [],
    listRevenuesExemptions: [],
    listCourses: [],
    listSemesters: [],
    allPolicies: [],
  },
  getters: {
    listExemptions: state => state.listExemptions,
    totalRows: state => state.totalRows,
    allTrainingSystems: state => state.allTrainingSystems,
    allMajors: state => state.allMajors,
    allPolicies: state => state.allPolicies,
    listRevenuesExemptions: state => state.listRevenuesExemptions,
    listCourses: state => state.listCourses,
    listSemesters: state => state.listSemesters,
  },
  mutations: {
    SET_EXEMPTIONS: (state, { total, records }) => {
      state.totalRows = total
      state.listExemptions = records
    },

    SET_ALL: (state, { allTrainingSystems, allMajors, listRevenuesExemptions, allPolicies }) => {
      state.allTrainingSystems = allTrainingSystems
      state.allMajors = allMajors
      state.allPolicies = allPolicies
      state.listRevenuesExemptions = listRevenuesExemptions
    },
    SET_COURSES: (state, { data }) => {
      state.listCourses = data
    },
    SET_SEMESTERS: (state, { data }) => {
      state.listSemesters = data
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseTrainingSystems = await axios.get('uni/exemptions/training_systems')
        const responseMajors = await axios.get('uni/exemptions/majors')
        const responseRevenues = await axios.get('uni/exemptions/revenues_exemptions')
        const responsePolicies = await axios.get('uni/exemptions/policies')

        if (responseTrainingSystems.data.code === ApiCode.SUCCESS && responseMajors.data.code === ApiCode.SUCCESS && responseRevenues.data.code === ApiCode.SUCCESS && responsePolicies.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allTrainingSystems: responseTrainingSystems.data.data.rs, allMajors: responseMajors.data.data.rs, listRevenuesExemptions: responseRevenues.data.data.rs, allPolicies: responsePolicies.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getExemptions({ commit }, params) {
      try {
        const response = await axios.get('uni/exemptions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_EXEMPTIONS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createExemption({ commit }, params) {
      try {
        const response = await axios.post('uni/exemptions', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateExemption({ commit }, params) {
      try {
        const response = await axios.put(`uni/exemptions/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteExemption({ commit }, id) {
      try {
        const response = await axios.delete(`uni/exemptions/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/exemptions/courses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', { data: response.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/exemptions/semesters', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
