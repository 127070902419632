import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    disciplines: [],
    totalRows: 0,
  },
  getters: {
    disciplines: state => state.disciplines,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_DISCIPLINES: (state, { total, disciplines }) => {
      state.totalRows = total
      state.disciplines = disciplines
    },
  },
  actions: {
    async getDisciplines({ commit }, params) {
      try {
        const response = await axios.get('uni/disciplines', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, disciplines } = data.data
          commit('SET_DISCIPLINES', { total, disciplines })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDiscipline({ commit }, params) {
      try {
        const response = await axios.post('uni/disciplines', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDiscipline({ commit }, params) {
      try {
        const response = await axios.put(`uni/disciplines/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDiscipline({ commit }, id) {
      try {
        const response = await axios.delete(`uni/disciplines/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
