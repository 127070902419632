import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'
import { PROGRAMME_STATUSES } from '@/const/status'

export const defaultParams = Object.freeze({
  currentPage: 1,
  itemsPerPage: 100,
  status: null,
  sort: 'null',
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    admissionExaminationSchedulers: [],
    statuses: PROGRAMME_STATUSES,
    resourceName: {
      fullName: 'Dự kiến lịch thi',
      shortName: 'Lịch thi',
    },
    maxOrderNo: 0,
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    admissionExaminationSchedulers: state => state.admissionExaminationSchedulers,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_ADMISSION_EXAMINATION_SCHEDULERS: (state, records) => {
      state.admissionExaminationSchedulers = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_examination_schedulers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData(commit, params) {
      try {
        const response = await axios.post('/admissions/admission_examination_schedulers', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_examination_schedulers/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_examination_schedulers/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAdmissionExaminationSchedulers({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get('/admissions/admission_examination_schedulers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_ADMISSION_EXAMINATION_SCHEDULERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
