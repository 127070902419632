import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    allowances: [],
    totalRows: 0,
  },
  getters: {
    allowances: state => state.allowances,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_ALLOWANCES: (state, { total, allowances }) => {
      state.totalRows = total
      state.allowances = allowances
    },
  },
  actions: {
    async getAllowances({ commit }, params) {
      try {
        const response = await axios.get('uni/allowances', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, allowances } = data.data
          commit('SET_ALLOWANCES', { total, allowances })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAllowance({ commit }, params) {
      try {
        const response = await axios.post('uni/allowances', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAllowance({ commit }, params) {
      try {
        const response = await axios.put(`uni/allowances/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAllowance({ commit }, id) {
      try {
        const response = await axios.delete(`uni/allowances/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
