import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/maintenances',
    name: 'maintenance',
    component: () => import('@/views/maintenance/Maintenance.vue'),
    meta: {
      pageTitle: 'Bảo trì hệ thống',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Bảo trì hệ thống',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MAINTENANCE,
    },
  },
  {
    path: '/backupSchedule',
    name: 'backupSchedule',
    component: () => import('@/views/maintenance/backup-schedule/BackupSchedule.vue'),
    meta: {
      pageTitle: 'Lịch sao lưu tự động',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Lịch sao lưu tự động',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.BACKUP_SCHEDULE,
    },
  },
]
