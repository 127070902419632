import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { TRANSFER_STATUSES, TRANSFER_SUBJECT_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.studentTransfer,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: TRANSFER_STATUSES,
    transferSubjectLists: [],
    totalSubjectRows: 0,
    transferSubjectStatuses: TRANSFER_SUBJECT_STATUSES,
    resourceName: {
      fullName: 'Kết quả duyệt học phần học viên học liên thông',
      shortName: 'Học viên',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    transferSubjectLists: state => state.transferSubjectLists,
    totalSubjectRows: state => state.totalSubjectRows,
    transferSubjectStatuses: state => state.transferSubjectStatuses,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_SUBJECT_LIST: (state, { total, records }) => {
      state.totalSubjectRows = total
      state.transferSubjectLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTransferSubjectLists({ commit }, params) {
      try {
        const id = params.studentTransferId
        const response = await axios.get(`${baseAttr.basePath}/${id}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SUBJECT_LIST', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    createPlanData: async function (commit, params) {
      try {
        const response = await axios.post(`${baseAttr.basePath}/bulkInsert`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async updateTransferSubject({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/transfer_subject/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
