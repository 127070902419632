import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    inventions: [],
    totalRows: 0,
    researches: [],
  },
  getters: {
    inventions: state => state.inventions,
    totalRows: state => state.totalRows,
    researches: state => state.researches,
  },
  mutations: {
    SET_INVENTIONS: (state, data) => { state.inventions = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_RESEARCHES: (state, data) => { state.researches = data },
  },
  actions: {
    async createInvention({ commit }, params) {
      try {
        const response = await axios.post('uni/researchInventions', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getInventions({ commit }, params) {
      try {
        const response = await axios.get('uni/researchInventions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_INVENTIONS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateInvention({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchInventions/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteInvention({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchInventions/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAllResearchesByOrganization({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/getAllByOrganization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCHES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
