import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentPolicies: [],
    totalRows: 0,
    allStudent: [],
    allPolicies: [],
  },
  getters: {
    studentPolicies: state => state.studentPolicies,
    totalRows: state => state.totalRows,
    allStudent: state => state.allStudent,
    allPolicies: state => state.allPolicies,
  },
  mutations: {
    SET_STUDENT_POLICIES: (state, { total, records }) => {
      state.totalRows = total
      state.studentPolicies = records
    },

    SET_ALL: (state, { allStudent, allPolicies }) => {
      state.allStudent = allStudent
      state.allPolicies = allPolicies
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseStudent = await axios.get('uni/studentPolicies/students')
        const responsePolicies = await axios.get('uni/studentPolicies/policies')

        if (responseStudent.data.code === ApiCode.SUCCESS && responsePolicies.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allStudent: responseStudent.data.data.students, allPolicies: responsePolicies.data.data.policies })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentPolicies({ commit }, params) {
      try {
        const response = await axios.get('uni/studentPolicies', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENT_POLICIES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentPolicies({ commit }, params) {
      try {
        const response = await axios.post('uni/studentPolicies', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentPolicies({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentPolicies/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentPolicies({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentPolicies/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
