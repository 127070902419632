import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionCommittees: [],
    totalRows: 0,
    statuses: STATUSES,
  },
  getters: {
    admissionCommittees: state => state.admissionCommittees,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_ADMISSION_COMMITTEES: (state, { total, records }) => {
      state.totalRows = total
      state.admissionCommittees = records
    },
  },
  actions: {
    async readAdmissionCommittees({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_committee', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_COMMITTEES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionCommittees({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_committee', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionCommittees({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_committee/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionCommittees({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_committee/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
