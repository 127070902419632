import { STATUSES, STATUSES_PROCESS_HISTORY } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES_PROCESS_HISTORY,
    totalRows: 0,
    credentialFilesRequire: [],
    credentialProcessRequest: [],
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    credentialFilesRequire: state => state.credentialFilesRequire,
    credentialProcessRequest: state => state.credentialProcessRequest,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, total) => {
      state.totalRows = total
    },
    SET_CREDENTIAL_FILES_REQUIRE: (state, credentialFilesRequire) => {
      state.credentialFilesRequire = credentialFilesRequire
    },
    SET_CREDENTIAL_PROCESS_REQUEST: (state, credentialProcessRequest) => {
      state.credentialProcessRequest = credentialProcessRequest
    },
  },
  actions: {
    async readCredentialProcessRequests({ commit }, params ) {
      try {
        const response = await axios.get('certificates/credential_process_requests',  {params} )
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_PROCESS_REQUEST', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readCredentialFileRequire({ commit }, { id }) {
      try {
        const response = await axios.get(`certificates/credential_file_require_process?idCredentialProcessType=${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_FILES_REQUIRE', records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadSample({ commit }, params) {
      try {
        const response = await axios.post('certificates/certificate-blanks/uploadSample', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getPathFileSample({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-blanks/getFileMinioByPathFile', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async createCredentialProcessRequest({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_process_requests', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCredentialProcessRequest({ commit }, {id, params}) {
      try {
        const response = await axios.put(`certificates/credential_process_requests/${id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
