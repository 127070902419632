import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    departments: [],
    dataSources: [],
    totalRows: 0,
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    departments: state => state.departments,
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_DEPARTMENTS: (state, data) => { state.departments = data },
    SET_DATA_SOURCES: (state, { total, records }) => {
      state.totalRows = total
      state.dataSources = records
    },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = mapDropdown(data.data)
          commit('SET_TRAINING_SYSTEMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDepartmentsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/departments/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = mapDropdown(data.data)
          commit('SET_DEPARTMENTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/getReportTotalLectureHoursPerCourse', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
