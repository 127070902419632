import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    subjectTypes: [],
    totalRows: 0,
    statuses: [],
    trainingForms: [],
  },
  getters: {
    subjectTypes: state => state.subjectTypes,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    trainingForms: state => state.trainingForms,
  },
  mutations: {
    SET_SUBJECT_TYPES: (state, { total, records }) => {
      state.totalRows = total
      state.subjectTypes = records
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TRAINING_FORMS: (state, trainingForms) => {
      state.trainingForms = trainingForms
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/subjectTypes/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses, trainingForms } = data.data
          commit('SET_STATUSES', statuses)
          commit('SET_TRAINING_FORMS', trainingForms)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readSubjectTypes({ commit }, params) {
      try {
        const response = await axios.get('uni/subjectTypes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SUBJECT_TYPES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSubjectTypes({ commit }, params) {
      try {
        const response = await axios.post('uni/subjectTypes', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSubjectTypes({ commit }, params) {
      try {
        const response = await axios.put(`uni/subjectTypes/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSubjectTypes({ commit }, id) {
      try {
        const response = await axios.delete(`uni/subjectTypes/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
