import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    teacherQuotas: [],
    totalRows: 0,
    departments: [],
  },
  getters: {
    teacherQuotas: state => state.teacherQuotas,
    totalRows: state => state.totalRows,
    departments: state => state.departments,
  },
  mutations: {
    SET_TW_TEACHER_QUOTAS: (state, records) => {
      state.teacherQuotas = records
    },
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
    SET_DEPARTMENTS: (state, records) => {
      state.departments = records
    },
  },
  actions: {
    async getDepartments({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '', leaderId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/departments', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DEPARTMENTS', data.data.records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readTeacherQuotas({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_quotas', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TW_TEACHER_QUOTAS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeacherQuotas({ commit }, params) {
      try {
        const response = await axios.post('uni/tw_teacher_quotas', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTeacherQuotas({ commit }, params) {
      try {
        const response = await axios.put(`uni/tw_teacher_quotas/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTeacherQuotas({ commit }, id) {
      try {
        const response = await axios.delete(`uni/tw_teacher_quotas/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
