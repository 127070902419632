import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionLanguageScores: [],
    totalRows: 0,
    statuses: STATUSES,
    dataAdmissionLanguageScores: [],
    dataByCriteriaId: []
  },
  getters: {
    admissionLanguageScores: state => state.admissionLanguageScores,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    dataAdmissionLanguageScores: state => state.dataAdmissionLanguageScores,
    dataByCriteriaId: state => state.dataByCriteriaId,
  },
  mutations: {
    SET_ADMISSION_LANGUAGE_SCORES: (state, { total, records }) => {
      state.totalRows = total
      state.admissionLanguageScores = records
    },
    SET_DATA_ADMISSION_LANGUAGE_SCORES: (state, dataAdmissionLanguageScores) => { state.dataAdmissionLanguageScores = dataAdmissionLanguageScores },
    SET_DATA_BY_CRITERIA_ID: (state, dataByCriteriaId) => { state.dataByCriteriaId = dataByCriteriaId },
  },
  actions: {
    async getDataByCriteriaId({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_language_score/getByCriteriaSetId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_BY_CRITERIA_ID', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readAdmissionLanguageScores({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_language_score', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_LANGUAGE_SCORES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataAdmissionLanguageScores({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_language_score/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_ADMISSION_LANGUAGE_SCORES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionLanguageScores({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_language_score', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionLanguageScores({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_language_score/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionLanguageScores({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_language_score/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
