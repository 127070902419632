import { CREDENTIAL_TYPE, STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    types: CREDENTIAL_TYPE,
    credentialTypes: [],
    totalRows: 0,
  },
  getters: {
    statuses: state => state.statuses,
    types: state => state.types,
    credentialTypes: state => state.credentialTypes,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_CREDENTIAL_TYPES: (state, credentialTypes) => {
      state.credentialTypes = credentialTypes
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
  },
  actions: {
    async readCredentialsType({ commit }, params) {
      try {
        const response = await axios.get('certificates/credential_types', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_TYPES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async createCredentialType({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_types', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateCredentialType({ commit }, params) {
      try {
        const response = await axios.put(`certificates/credential_types/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteCredentialType({ commit }, id) {
      try {
        const response = await axios.delete(`certificates/credential_types/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getCredentialTypeById({ commit }, id) {
      try {
        const response = await axios.get(`certificates/credential_types/getById?id=${id}`)
        const { data } = response
        return {data}
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
