import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ADMISSION_CONFIRM_CERTIFICATE_STATUSES, ADMISSION_CONFIRM_CERTIFICATE_TYPES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: ADMISSION_CONFIRM_CERTIFICATE_STATUSES,
    typeList: ADMISSION_CONFIRM_CERTIFICATE_TYPES,
    resourceName: {
      fullName: 'Xác nhận chứng chỉ của thí sinh',
      shortName: 'Xác nhận',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    typeList: state => state.typeList,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/applicant_students/getAllConfirmCertificate', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put('/admissions/applicant_students/updateAdmissionConfirmCertificate', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    
  },
}
