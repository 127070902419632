import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    policies: [],
    totalRows: 0,
  },
  getters: {
    policies: state => state.policies,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_POLICIES: (state, { total, policies }) => {
      state.totalRows = total
      state.policies = policies
    },
  },
  actions: {
    async getPolicies({ commit }, params) {
      try {
        const response = await axios.get('uni/policies', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, policies } = data.data
          commit('SET_POLICIES', { total, policies })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createPolicies({ commit }, params) {
      try {
        const response = await axios.post('uni/policies', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updatePolicies({ commit }, params) {
      try {
        const response = await axios.put(`uni/policies/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deletePolicies({ commit }, id) {
      try {
        const response = await axios.delete(`uni/policies/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
