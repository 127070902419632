import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    articles: [],
    total: 0,
    statuses: [],
    maxOrderNo: 0,
  },
  getters: {
    articles: state => state.articles,
    total: state => state.total,
    statuses: state => state.statuses,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_ARTICLES: (state, articles) => { state.articles = articles },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
  },

  actions: {

    async getArticles({ commit }, body) {
      try {
        const response = await axios.post('uni/article/list', body)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_ARTICLES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async updateArticles({ commit }, dataObj) {
      try {
        const response = await axios.put('uni/article/update', dataObj)
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async createArticles({ commit }, dataObj) {
      try {
        const response = await axios.post('uni/article/createOrUpdate', dataObj)
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteArticle({ commit }, id) {
      try {
        const response = await axios.delete(`uni/article/${id}`)
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async getMaxOrderNo({ commit }, id) {
      try {
        const response = await axios.get(`uni/article/max/order-no/${id}`)
        const { data } = response
        const { maxOrderNo } = data.data
        commit('SET_MAX_ORDER_NO', maxOrderNo)
      } catch (e) {
        handleCatch(e)
      }
    },

    async uploadFile({ commit }, { id, params }) {
      try {
        const response = await axios.post('uni/article/uploadFile', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getLinkFile({ commit }, id) {
      try {
        const response = await axios.get(`uni/article/getFileMinioByMediaId?id=${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
