import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    dataArr: [],
    
    dataMemberArr: [],
    dataReviewBoardArr: [],
    researchReviewCommittees: [],
    totalResearchReviewCommittees: 0,
    researchReviewResults: [],
  },
  getters: {
    dataArr: state => state.dataArr,
    total: state => state.total,
    dataMemberArr: state => state.dataMemberArr,
    dataReviewBoardArr: state => state.dataReviewBoardArr,
    researchReviewCommittees: state => state.researchReviewCommittees,
    totalResearchReviewCommittees: state => state.totalResearchReviewCommittees,
    researchReviewResults: state => state.researchReviewResults,
  },
  mutations: {
    SET_DATA: (state, dataArr) => { state.dataArr = dataArr },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_MEMBER: (state, dataMemberArr) => { state.dataMemberArr = dataMemberArr },
    SET_REVIEW_BOARDS: (state, dataReviewBoardArr) => { state.dataReviewBoardArr = dataReviewBoardArr },
    SET_RESEARCH_REVIEW_COMMITTEES: (state, researchReviewCommittees) => { state.researchReviewCommittees = researchReviewCommittees },
    SET_TOTAL_RESEARCH_REVIEW_COMMITTEES: (state, totalResearchReviewCommittees) => { state.totalResearchReviewCommittees = totalResearchReviewCommittees },
    SET_RESEARCH_REVIEW_RESULTS: (state, researchReviewResults) => { state.researchReviewResults = researchReviewResults },
  },

  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/researches', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DATA', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDataMember({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/get_member', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_MEMBER', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDataReviewBoards({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/get_review_board', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_REVIEW_BOARDS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researches/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataMember({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researches/research_member/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataResearchReviewBoard({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researches/research_review_boards/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async saveData({ commit }, body) {
      try {
        const response = await axios.post('uni/researches', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async insertDataMember({ commit }, body) {
      try {
        const response = await axios.post('uni/researches/insert_member', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async insertDataResearchReviewBoard({ commit }, body) {
      try {
        const response = await axios.post('uni/researches/insert_review_board', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getResearchReviewCommittee({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/research_review_committees', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_RESEARCH_REVIEW_COMMITTEES', records)
          commit('SET_TOTAL_RESEARCH_REVIEW_COMMITTEES', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async saveResearchReviewCommittee({ commit }, body) {
      try {
        const response = await axios.post('uni/researches/research_review_committees', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    
    async deleteResearchReviewCommittee({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researches/research_review_committees/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getResearchReviewResults({ commit }, params) {
      try {
        const response = await axios.get('uni/researches/research_review_results', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCH_REVIEW_RESULTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async saveResearchReviewResults({ commit }, body) {
      try {
        const response = await axios.post('uni/researches/research_review_results', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
