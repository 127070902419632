import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/calculateCgpa',
    name: 'calculateCgpa',
    component: () => import('@/views/calculate-cgpa/CalculateCgpa.vue'),
    meta: {
      pageTitle: 'Tổng kết học kỳ',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Tổng kết học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CALCULATE_CGPA,
    },
  },
]
