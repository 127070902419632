import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentDisciplines: [],
    totalRows: 0,
    allStudent: [],
    allDiscipline: [],
  },
  getters: {
    studentDisciplines: state => state.studentDisciplines,
    totalRows: state => state.totalRows,
    allStudent: state => state.allStudent,
    allDiscipline: state => state.allDiscipline,
  },
  mutations: {
    SET_STUDENTDISCIPLINES: (state, { total, records }) => {
      state.totalRows = total
      state.studentDisciplines = records
    },

    SET_ALL: (state, { allStudent, allDiscipline }) => {
      state.allStudent = allStudent
      state.allDiscipline = allDiscipline
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseStudent = await axios.get('uni/studentDisciplines/students')
        const responseDiscipline = await axios.get('uni/studentDisciplines/disciplines')

        if (responseStudent.data.code === ApiCode.SUCCESS && responseDiscipline.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allStudent: responseStudent.data.data.students, allDiscipline: responseDiscipline.data.data.disciplines })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentDisciplines({ commit }, params) {
      try {
        const response = await axios.get('uni/studentDisciplines', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENTDISCIPLINES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentDiscipline({ commit }, params) {
      try {
        const response = await axios.post('uni/studentDisciplines', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentDiscipline({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentDisciplines/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentDiscipline({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentDisciplines/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
