import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ABSENT_TYPES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    studentGraduationProjects: [],
    graduationProjectRegisterRounds: [],
  },
  getters: {
    studentGraduationProjects: state => state.studentGraduationProjects,
    graduationProjectRegisterRounds: state => state.graduationProjectRegisterRounds,
  },
  mutations: {
    SET_STUDENT_GRADUATION_PROJECTS: (state, { data }) => {
      state.studentGraduationProjects = data
    },
    SET_GRADUATION_PROJECT_REGISTER_ROUNDS: (state, { total, records }) => {
      state.totalRows = total
      state.graduationProjectRegisterRounds = records
    },
  },
  actions: {
    async getGraduationProjectRegisterRounds({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationProjectRegisterRounds', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GRADUATION_PROJECT_REGISTER_ROUNDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentGraduationProjects( { commit }, params ) {
      try {
        const response = await axios.get( 'uni/graduationProjectScore', { params } )
        if ( response.data.code === ApiCode.SUCCESS ) {
          commit( 'SET_STUDENT_GRADUATION_PROJECTS', { data: response.data.data } )
        }
      } catch ( e ) {
        handleCatch( e )
      }
    },
    async saveScores({ commit }, params) {
      try {
        const response = await axios.put('uni/graduationProjectScore/saveScores', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
