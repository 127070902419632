import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    articleMembers: [],
    employees: [],
    roles: [],
  },
  getters: {
    articleMembers: state => state.articleMembers,
    employees: state => state.employees,
  },
  mutations: {
    SET_ARTICLE_MEMBERS: (state, data) => { state.articleMembers = data },
    SET_EMPLOYEES: (state, data) => { state.employees = data },
  },
  actions: {
    async createArticleMember({ commit }, params) {
      try {
        const response = await axios.post('uni/researchArticleMembers', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getArticleMembers({ commit }, params) {
      try {
        const response = await axios.get('uni/researchArticleMembers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_ARTICLE_MEMBERS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateArticleMember({ commit }, params) {
      try {
        const response = await axios.put(`uni/researchArticleMembers/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteArticleMember({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchArticleMembers/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getEmployeesByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/employees/getAllByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
