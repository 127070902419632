import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    summarySemesters: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    summarySemesters: state => state.summarySemesters,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_SUMMARY_SEMESTERS: (state, data) => { state.summarySemesters = data },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSummarySemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/studentCgpa/getSummarySemesters', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SUMMARY_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async calculateCgpaByCourseSemester({ commit }, params) {
      try {
        const response = await axios.post('mq/calculateCgpa', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
