import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async chiTietBanDoc({commit}, body) {
            try {
                const response = await axios.post('uni/elearning/chi-tiet-ban-doc', body)
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    return data.data
                }
                return false
            } catch (e) {
                handleCatch(e)
            }
        },

        async soLuongNguoiDung({commit}, body) {
            try {
                const response = await axios.post('uni/elearning/so-luong-nguoi-dung', body)
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    return data.data
                }
                return false
            } catch (e) {
                handleCatch(e)
            }
        },


        async soMuonTra({commit}, body) {
            try {
                const response = await axios.post('uni/elearning/so-muon-tra', body)
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    return data.data
                }
                return false
            } catch (e) {
                handleCatch(e)
            }
        },


    },
}
