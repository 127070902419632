import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    revenues: [],
    totalRows: 0,
    allRevenueTypes: [],
  },
  getters: {
    revenues: state => state.revenues,
    totalRows: state => state.totalRows,
    allRevenueTypes: state => state.allRevenueTypes,
  },
  mutations: {
    SET_REVENUES: (state, { total, records }) => {
      state.totalRows = total
      state.revenues = records
    },

    SET_ALL: (state, { allRevenueTypes }) => {
      state.allRevenueTypes = allRevenueTypes
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseRevenueTypes = await axios.get('uni/revenues/revenueTypes')

        if (responseRevenueTypes.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allRevenueTypes: responseRevenueTypes.data.data.revenueTypes })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getRevenues({ commit }, params) {
      try {
        const response = await axios.get('uni/revenues', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_REVENUES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createRevenue({ commit }, params) {
      try {
        const response = await axios.post('uni/revenues', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateRevenue({ commit }, params) {
      try {
        const response = await axios.put(`uni/revenues/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRevenue({ commit }, id) {
      try {
        const response = await axios.delete(`uni/revenues/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
