import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    days: [],
    total: 0,
  },
  getters: {
    days: state => state.days,
    total: state => state.total,
  },
  mutations: {
    SET_DAYS: (state, days) => { state.days = days },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {
    async getDays({ commit }) {
      try {
        const response = await axios.get('uni/days/get_all_day')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          commit('SET_DAYS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
