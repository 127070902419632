import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    admissionMajorForms: [],
    dataListAdmissionBlockByAdmissionMajorForm: [],
    checkQuota: true,
  },
  getters: {
    admissionMajorForms: state => state.admissionMajorForms,
    dataListAdmissionBlockByAdmissionMajorForm: state => state.dataListAdmissionBlockByAdmissionMajorForm,
    checkQuota: state => state.checkQuota,
  },
  mutations: {
    SET_ADMISSION_MAJOR_FORMS: (state, data) => { state.admissionMajorForms = data },
    SET_ADMISSION_BLOCK_BY_ADMISSION_MAJOR_FORM_ID: (state, data) => { state.dataListAdmissionBlockByAdmissionMajorForm = data },
    SET_CHECK_QUOTA: (state, data) => { state.checkQuota = data },
  },
  actions: {
    async getAdmissionMajorForm({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ADMISSION_MAJOR_FORMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionMajorForm({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_major_form', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionMajorForm({ commit }, param) {
      try {
        const response = await axios.put(`/admissions/admission_major_form/${param.id}`, param)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionMajorForm({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_major_form/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async checkQuota({ commit }, params) {
      try {
        const response = await axios.get(`/admissions/admission_major_form/checkQuota/${params.id}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CHECK_QUOTA', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionBlockByAdmissionMajorFormId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form/getByAdmissionMajorFormId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.admissionBlockId,
            label: item.admissionBlockName,
          }))
          commit('SET_ADMISSION_BLOCK_BY_ADMISSION_MAJOR_FORM_ID', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
