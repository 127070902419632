import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ABSENT_TYPES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    studentExaminationRooms: [],
    codeByAdmissionExaminationRooms: [],
    scoreByCreditClass: [],
    title: [],
    examinationRoomSubjects: [],
    checkExaminationDataSources: [],
    absentTypes: ABSENT_TYPES,
  },
  getters: {
    studentExaminationRooms: state => state.studentExaminationRooms,
    codeByAdmissionExaminationRooms: state => state.codeByAdmissionExaminationRooms,
    scoreByCreditClass: state => state.scoreByCreditClass,
    title: state => state.title,
    examinationRoomSubjects: state => state.examinationRoomSubjects,
    checkExaminationDataSources: state => state.checkExaminationDataSources,
    absentTypes: state => state.absentTypes,
  },
  mutations: {
    SET_APPLICANT_STUDENT_EXAMINATION_ROOMS: (state, { data }) => {
      state.studentExaminationRooms = data
    },
    SET_CODE_BY_ADMISSION_EXAMINATION_ROOMS: (state, { data }) => {
      state.codeByAdmissionExaminationRooms = data
    },

    SET_SUBJECT: (state, { data }) => {
      state.subjects = data
    },

    SET_TITLE: (state, { data }) => {
      state.title = data
    },

    SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS: (state, { data }) => {
      state.examinationRoomSubjects = data
    },

    SET_ALL: (state, { allTrainingSystem }) => {
      state.allTrainingSystem = allTrainingSystem
    },
    SET_SCORE_BY_CREDIT_CLASS: (state, { records }) => {
      state.scoreByCreditClass = records
    },
    SET_CHECK_EXAMINATION_DATA_SOURCES: (state, data) => {
      state.checkExaminationDataSources = data
    },
  },
  actions: {
    async getSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/courseSemesterSubject', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECT', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getTitle({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms/studentTitleExaminationRooms', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_TITLE', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async exportPdfDanhSachSinhVien({ commit }, params) {
      try {
        const response = await axios.get('admissions/reports/exportPdfDanhSachSinhVien', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getExaminationRoomSubjects({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms/examinationRoomSubjects', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_APPLICANT_STUDENT_EXAMINATION_ROOM_SUBJECTS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_APPLICANT_STUDENT_EXAMINATION_ROOMS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCodeByAdmissionExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms/codes', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_CODE_BY_ADMISSION_EXAMINATION_ROOMS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateStudentExaminationRoom({ commit }, params) {
      try {
        const response = await axios.put('uni/studentExaminationRooms', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async banStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('admissions/applicantStudentExaminationRooms/banStudent', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deductedStudentExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put(`admissions/applicantStudentExaminationRooms/deductedStudent/${params.deductId}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async changeExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('admissions/applicantStudentExaminationRooms/changeExaminationRooms', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveScoresByCode({ commit }, params) {
      try {
        const response = await axios.put('admissions/applicantStudentExaminationRooms/saveScoresByCode', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCheckExaminationPapers({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms/checkExaminationPapers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CHECK_EXAMINATION_DATA_SOURCES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createCheckExaminationPaper({ commit }, params) {
      try {
        const response = await axios.post('admissions/applicantStudentExaminationRooms/checkExaminationPapers', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveAbsent({ commit }, params) {
      try {
        const response = await axios.put(`admissions/applicantStudentExaminationRooms/saveAbsent/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfStudentsExaminationScore({ commit }, params) {
      try {
        const response = await axios.get('admissions/reports/exportPdfStudentsExaminationScore', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfScoreHeaderCode({ commit }, params) {
      try {
        const response = await axios.get('admissions/reports/exportPdfScoreHeaderCode', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
