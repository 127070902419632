import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    awards: [],
    totalRows: 0,
  },
  getters: {
    awards: state => state.awards,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_AWARDS: (state, data) => { state.awards = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
  },
  actions: {
    async getAwards({ commit }, params) {
      try {
        const response = await axios.get('uni/awards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_AWARDS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAward({ commit }, params) {
      try {
        const response = await axios.post('uni/awards', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAward({ commit }, params) {
      try {
        const response = await axios.put(`uni/awards/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAward({ commit }, id) {
      try {
        const response = await axios.delete(`uni/awards/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
