import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    majors: [],
    totalRows: 0,
    statuses: STATUSES,
    departments: [],
  },
  getters: {
    majors: state => state.majors,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    departments: state => state.departments,
  },
  mutations: {
    SET_MAJORS: (state, data) => {
      state.majors = data
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_DEPARTMENTS: (state, departments) => {
      state.departments = departments
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/majors/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses, departments } = data.data
          commit('SET_STATUSES', statuses)
          commit('SET_DEPARTMENTS', departments)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readMajors({ commit }, params) {
      try {
        const response = await axios.get('uni/majors', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_MAJORS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createMajors({ commit }, params) {
      try {
        const response = await axios.post('uni/majors', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateMajors({ commit }, params) {
      try {
        const response = await axios.put(`uni/majors/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteMajors({ commit }, id) {
      try {
        const response = await axios.delete(`uni/majors/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
