import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/admin/studentManagement/student',
    name: 'ImportStudent',
    component: () => import('@/views/admin/studentManagement/importStudent/Student.vue'),
    meta: {
      title: 'Nhập danh sách học viên trúng tuyển',
      pageTitle: 'Nhập danh sách học viên trúng tuyển',
      breadcrumb: [
        {
          text: 'Quản lý học viên',
          active: false,
        },
        {
          text: 'Nhập danh sách học viên trúng tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.IMPORT_STUDENT,
    },
  },
  {
    path: '/admin/info_student_register_subject/:courseSemesterId/:studentId',
    name: 'infoStudentRegisterSubject',
    component: () => import('@/views/student-class/InfoStudentRegisterSubject'),
    meta: {
      title: 'Thông tin học viên đăng ký học phần theo kỳ',
    },
  },
  {
    path: '/admin/studentManagement/searchStudent',
    name: 'searchStudent',
    component: () => import('@/views/admin/studentManagement/searchStudent/searchStudent.vue'),
    meta: {
      pageTitle: 'Tìm kiếm học viên',
      breadcrumb: [
        {
          text: 'Quản lý học viên',
          active: false,
        },
        {
          text: 'Tìm kiếm học viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_CLASS,
    },
  },
  {
    path: '/admin/studentManagement/importStudentProgramme',
    name: 'ImportStudentByProgramme',
    component: () => import('@/views/admin/studentManagement/importStudentProgramme/Student.vue'),
    meta: {
      pageTitle: 'Nhập danh sách học viên theo chương trình đào tạo',
      breadcrumb: [
        {
          text: 'Quản lý học viên',
          active: false,
        },
        {
          text: 'Nhập danh sách học viên trúng tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.IMPORT_STUDENT_PROGRAMME,
    },
  },
]
