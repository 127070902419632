import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    semesterTypes: [],
    totalRows: 0,
    statuses: [],
    resourceName: { fullName: 'Loại học kỳ' },
  },
  getters: {
    semesterTypes: state => state.semesterTypes,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_SEMESTER_TYPES: (state, { total, records }) => {
      state.totalRows = total
      state.semesterTypes = records
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('uni/semesterTypes/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses } = data.data
          commit('SET_STATUSES', statuses)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesterTypes({ commit }, params) {
      try {
        const response = await axios.get('uni/semesterTypes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SEMESTER_TYPES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSemesterType({ commit }, params) {
      try {
        const response = await axios.post('uni/semesterTypes', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSemesterType({ commit }, params) {
      try {
        const response = await axios.put(`uni/semesterTypes/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSemesterType({ commit }, id) {
      try {
        const response = await axios.delete(`uni/semesterTypes/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
