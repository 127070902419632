import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    maxOrderNo: 0,
    scoringFormulas: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    maxOrderNo: state => state.maxOrderNo,
    scoringFormulas: state => state.scoringFormulas,
  },
  mutations: {
    SET_LISTS: (state, data) => {
      state.dataLists = data
    },
    SET_MAX_ORDER_NO: (state, data) => {
      state.maxOrderNo = data
    },
    SET_SCORING_FORMULAS: (state, data) => {
      state.scoringFormulas = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/generalScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_LISTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/generalScores', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/generalScores/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/generalScores/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('uni/generalScores/maxOrderNo', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAX_ORDER_NO', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getScoringFormulasByOrganization({ commit }, params) {
      try {
        const response = await axios.get('uni/scoringFormulas/getByOrganization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SCORING_FORMULAS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
