import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    resourceName: {
      fullName: 'Thí sinh xác nhận nhập học',
      shortName: 'Xác nhận nhập học',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information/confirmAdmission', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
