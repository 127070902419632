import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ADMISSION_REVENUE_PLAN_TYPES } from '@/const/status'
export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    maxOrderNo: 0,
    admissionRevenueTypes: [],
    typeAdmissionRevenuePlans: ADMISSION_REVENUE_PLAN_TYPES
  },
  getters: {
    dataSources: state => state.dataSources,
    typeAdmissionRevenuePlans: state => state.typeAdmissionRevenuePlans,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
    admissionRevenueTypes: state => state.admissionRevenueTypes,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
    SET_DATA_LIST: (state, admissionRevenueTypes) => { state.admissionRevenueTypes = admissionRevenueTypes },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_revenue_type', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionRevenueTypes({ commit }) {
      try {
        const response = await axios.get('/admissions/admission_revenue_type/allActive')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_revenue_type', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_revenue_type/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_revenue_type/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_revenue_type/max/order-no', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
