import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_RESOURCES: (state, { total, records }) => {
      state.totalRows = total
      state.dataSources = records
    },
  },
  actions: {
    async getResources({ commit }, { params }) {
      try {
        const response = await axios.get('uni/qbMultipleChoiceAnswers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_RESOURCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, { id, createChoiceAnswersReqDtos }) {
      try {
        const response = await axios.post(`uni/qbMultipleChoiceAnswers/${id}`, { createChoiceAnswersReqDtos })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, { id, param }) {
      try {
        const response = await axios.delete(`uni/qbMultipleChoiceAnswers/${id}`, { data: param })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
