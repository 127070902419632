import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/admin/category/building',
    name: 'categoryBuilding',
    component: () => import('@/views/admin/category/building/index.vue'),
    meta: {
      pageTitle: 'Quản lý tòa nhà',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Quản lý tòa nhà',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.BUILDING,
    },
  },
  {
    path: '/admin/category/class',
    name: 'class',
    component: () => import('@/views/admin/category/class/Class.vue'),
    meta: {
      pageTitle: 'Quản lý lớp cố định',
      breadcrumb: [
        {
          text: 'Quản lý học viên',
          active: false,
        },
        {
          text: 'Quản lý lớp cố định',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CLASSES,
    },
  },
  {
    path: '/admin/category/rooms',
    name: 'rooms',
    component: () => import('@/views/admin/category/rooms/Rooms.vue'),
    meta: {
      pageTitle: 'Quản lý phòng học',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Phòng học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ROOMS,
    },
  },
  {
    path: '/category/factScholarship',
    name: 'factScholarship',
    component: () => import('@/views/admin/category/fact-scholarship/Index.vue'),
    meta: {
      pageTitle: 'Quản lý quỹ Học bổng',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học bổng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_SCHOLARSHIP,
    },
  },
  {
    path: '/category/factAward',
    name: 'factAward',
    component: () => import('@/views/admin/category/fact-award/Index.vue'),
    meta: {
      pageTitle: 'Hình thức khen thưởng',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức khen thưởng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_AWARD,
    },
  },
  {
    path: '/category/factNationality',
    name: 'factNationality',
    component: () => import('@/views/admin/category/fact-nationality/Index.vue'),
    meta: {
      pageTitle: 'Danh mục quốc tịch',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục quốc tịch',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_NATIONALITY,
    },
  },
  {
    path: '/category/factReligion',
    name: 'factReligion',
    component: () => import('@/views/admin/category/fact-religion/Index.vue'),
    meta: {
      pageTitle: 'Danh mục tôn giáo',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục tôn giáo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RELIGION,
    },
  },
  {
    path: '/category/factProvince',
    name: 'factProvince',
    component: () => import('@/views/admin/category/fact-province/Index.vue'),
    meta: {
      pageTitle: 'Danh mục tỉnh thành',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh mục tỉnh thành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_PROVINCE,
    },
  },
  {
    path: '/category/revenueType',
    name: 'revenueType',
    component: () => import('@/views/admin/category/revenuetype/RevenueType.vue'),
    meta: {
      pageTitle: 'Danh sách Loại khoản thu',
      breadcrumb: [
        {
          text: 'Quản lý tài chính',
          active: false,
        },
        {
          text: 'Loại khoản thu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REVENUETYPES,
    },
  },
  {
    path: '/category/discipline',
    name: 'discipline',
    component: () => import('@/views/admin/category/fact-discipline/Index.vue'),
    meta: {
      pageTitle: 'Hình thức kỷ luật',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức kỷ luật',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DISCIPLINE,
    },
  },
  {
    path: '/category/factDisability',
    name: 'factDisability',
    component: () => import('@/views/admin/category/fact-disability/Index.vue'),
    meta: {
      pageTitle: 'Loại khuyết tật',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại khuyết tật',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DISABILITY,
    },
  },
  {
    path: '/category/factInfringe',
    name: 'factInfringe',
    component: () => import('@/views/admin/category/fact-infringe/Index.vue'),
    meta: {
      pageTitle: 'Hình thức vi phạm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức vi phạm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_INFRINGE,
    },
  },
  {
    path: '/category/systemParameter',
    name: 'systemParameter',
    component: () => import('@/views/admin/category/systemParameter/Index.vue'),
    meta: {
      pageTitle: 'Tham số hệ thống',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Tham số hệ thống',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SYSTEM_PARAMETER,
    },
  },
  {
    path: '/category/factYear',
    name: 'factYear',
    component: () => import('@/views/admin/category/fact-year/Index.vue'),
    meta: {
      pageTitle: 'Năm học',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Năm học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_YEAR,
    },
  },
  {
    path: '/category/policies',
    name: 'policies',
    component: () => import('@/views/admin/category/policies/Policies.vue'),
    meta: {
      pageTitle: 'Danh sách Loại chế độ chính sách',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Loại chế độ chính sách',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_POLICY,
    },
  },
  {
    path: '/category/allowance',
    name: 'allowance',
    component: () => import('@/views/admin/category/allowance/Allowance.vue'),
    meta: {
      pageTitle: 'Danh sách Trợ cấp sư phạm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Trợ cấp sư phạm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ALLOWANCE,
    },
  },
  {
    path: '/category/departments',
    name: 'departments',
    component: () => import('@/views/admin/category/department/Departments.vue'),
    meta: {
      pageTitle: 'Dánh sách Khoa/Bộ môn/Phòng ban',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: true,
        },
        {
          text: 'Khoa/Bộ môn/Phòng ban',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.DEPARTMENT,
    },
  },
  {
    path: '/category/specialize',
    name: 'majors',
    component: () => import('@/views/admin/category/major/Major.vue'),
    meta: {
      pageTitle: 'Quản lý khối kiến thức',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý khối kiến thức',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MAJOR,
    },
  },
  {
    path: '/category/subjectTypes',
    name: 'subjectTypes',
    component: () => import('@/views/admin/category/subjectType/SubjectTypes.vue'),
    meta: {
      pageTitle: 'Danh sách Loại học phần',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loại học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SUBJECT_TYPE,
    },
  },
  {
    path: '/category/subjects',
    name: 'subjects',
    component: () => import('@/views/admin/category/subject/Subjects.vue'),
    meta: {
      pageTitle: 'Quản lý học phần',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SUBJECT,
    },
  },
  {
    path: '/category/factTitles',
    name: 'factTitles',
    component: () => import('@/views/admin/category/fact-title/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Chức danh',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách chức danh',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_TITLE,
    },
  },
  {
    path: '/admin/category/employees',
    name: 'employees',
    component: () => import('@/views/admin/category/employee/Employee.vue'),
    meta: {
      // pageTitle: 'Danh sách cán bộ',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Danh sách cán bộ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EMPLOYEE,
    },
  },
  {
    path: '/admin/category/employees/import',
    name: 'importEmployee',
    component: () => import('@/views/admin/category/employee/importEmployee/Index.vue'),
    meta: {
      // pageTitle: 'Danh sách cán bộ',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Import danh sách cán bộ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.IMPORT_EMPLOYEE,
    },
  },
  {
    path: '/category/teacherTeams',
    name: 'teacherTeams',
    component: () => import('@/views/admin/category/teacherTeam/TeacherTeam.vue'),
    meta: {
      pageTitle: 'Danh sách tổ bộ môn',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Tổ bộ môn',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TEACHER_TEAM,
    },
  },
  {
    path: '/category/semesterType',
    name: 'categorySemesterType',
    component: () => import('@/views/admin/category/semester-type/SemesterType.vue'),
    meta: {
      pageTitle: 'Loại học kỳ',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SEMESTERTYPE,
    },
  },
  {
    path: '/category/semester',
    name: 'categorySemester',
    component: () => import('@/views/admin/category/semester/index.vue'),
    meta: {
      pageTitle: 'Danh mục Học kỳ',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Danh sách Học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SEMESTER,
    },
  },
  {
    path: '/category/trainingForm',
    name: 'categoryTrainingForm',
    component: () => import('@/views/admin/category/fact-training-form/Index.vue'),
    meta: {
      pageTitle: 'Loại hình đào tạo',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại hình đào tạo',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TRAININGFORM,
    },
  },
  {
    path: '/category/trainingSystem',
    name: 'categoryTrainingSystem',
    component: () => import('@/views/admin/category/training-system/index.vue'),
    meta: {
      pageTitle: 'Chương trình đào tạo/Bồi dưỡng',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Chương trình đào tạo/Bồi dưỡng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TRAININGSYSTEM,
    },
  },
  {
    path: '/category/course',
    name: 'categoryCourse',
    component: () => import('@/views/admin/category/course/index.vue'),
    meta: {
      pageTitle: 'Quản lý Khóa học',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý Khóa học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.COURSE,
    },
  },
  {
    path: '/category/trainingCourse',
    name: 'trainingCourse',
    component: () => import('@/views/admin/category/training_course/index.vue'),
    meta: {
      pageTitle: 'Quản lý Bồi dưỡng',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Quản lý Bồi dưỡng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TRAINING_COURSE,
    },
  },
  {
    path: '/category/conduct',
    name: 'categoryConduct',
    component: () => import('@/views/admin/category/conduct/Conduct.vue'),
    meta: {
      pageTitle: 'Danh sách Bộ tiêu chí đánh giá',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Bộ tiêu chí đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CONDUCT,
    },
  },
  {
    path: '/category/factOrganizations',
    name: 'factOrganizations',
    component: () => import('@/views/admin/category/fact-organization/Index.vue'),
    meta: {
      pageTitle: 'Danh sách cơ quan/đơn vị',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách đơn vị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ORGANIZATION,
    },
  },
  {
    path: '/category/student-status',
    name: 'studentStatus',
    component: () => import('@/views/admin/category/fact-student-status/Index.vue'),
    meta: {
      pageTitle: 'Danh sách trạng thái học viên',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách trạng thái học viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_STUDENT_STATUS,
    },
  },
  {
    path: '/category/student-credit-class-status',
    name: 'studentCreditClassStatus',
    component: () => import('@/views/admin/category/fact-student-credit-class-status/Index.vue'),
    meta: {
      pageTitle: 'Danh sách trạng thái học viên lớp độc lập',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách trạng thái học viên lớp độc lập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_STUDENT_CREDIT_CLASS_STATUS,
    },
  },
  {
    path: '/category/research-invention-type',
    name: 'researchInventionType',
    component: () => import('@/views/admin/category/fact-research-invention-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại giải pháp, sáng chế',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loại giải pháp, sáng chế',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_RESEARCH_INVENTION_TYPE,
    },
  },
  {
    path: '/category/ward',
    name: 'ward',
    component: () => import('@/views/admin/category/fact-ward/Index.vue'),
    meta: {
      pageTitle: 'Danh sách phường/xã',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách phường/xã',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_WARD,
    },
  },
  {
    path: '/category/factDistrict',
    name: 'district',
    component: () => import('@/views/admin/category/fact-district/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Quận huyện',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Quận huyện',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DISTRICT,
    },
  },
  {
    path: '/category/article-category',
    name: 'portalArticleCategory',
    component: () => import('@/views/admin/category/fact-article-category/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Loại bài viết',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Loại bài viết',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PORTAL_ARTICLE_CATEGORY,
    },
  },
  {
    path: '/category/ethnics',
    name: 'factEthnics',
    component: () => import('@/views/admin/category/fact-ethnic/Index.vue'),
    meta: {
      pageTitle: 'Danh sách Dân tộc',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách Dân tộc',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ETHNIC,
    },
  },
  {
    path: '/category/departmentType',
    name: 'departmentType',
    component: () => import('@/views/admin/category/department-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại phòng ban',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loai phòng ban',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_DEPARTMENT_TYPE,
    },
  },
  {
    path: '/category/chart',
    name: 'chart',
    component: () => import('@/views/admin/category/charts/Chart.vue'),
    meta: {
      pageTitle: 'Biểu đồ tổng quan',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Biểu đồ tổng quan',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CHARTS,
    },
  },
  {
    path: '/category/factAcademicRank',
    name: 'factAcademicRank',
    component: () => import('@/views/admin/category/fact-academic-rank/Index.vue'),
    meta: {
      pageTitle: 'Học hàm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học hàm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ACADEMIC_RANK,
    },
  },
  {
    path: '/category/factAcademicDegree',
    name: 'factAcademicDegree',
    component: () => import('@/views/admin/category/fact-academic-degree/Index.vue'),
    meta: {
      pageTitle: 'Học vị',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Học vị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ACADEMIC_DEGREE,
    },
  },
  {
    path: '/category/factGender',
    name: 'factGender',
    component: () => import('@/views/admin/category/fact-gender/Index.vue'),
    meta: {
      pageTitle: 'Giới tính',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Giới tính',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_GENDER,
    },
  },
  //Admission
  {
    path: '/category/factAdmissionFormTypes',
    name: 'factAdmissionFormTypes',
    component: () => import('@/views/admin/category/fact-admission-form-type/Index.vue'),
    meta: {
      pageTitle: 'Phương thức xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Phương thức xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_FORM_TYPE,
    },
  },
  {
    path: '/category/factAdmissionSubjectTypes',
    name: 'factAdmissionSubjectTypes',
    component: () => import('@/views/admin/category/fact-admission-subject-type/Index.vue'),
    meta: {
      pageTitle: 'Loại môn học xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại môn học xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_SUBJECT_TYPE,
    },
  },
  {
    path: '/category/factAdmissionSubjects',
    name: 'factAdmissionSubjects',
    component: () => import('@/views/admin/category/fact-admission-subject/Index.vue'),
    meta: {
      pageTitle: 'Môn học xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Môn học xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_SUBJECT,
    },
  },
  {
    path: '/category/factAdmissionBlocks',
    name: 'factAdmissionBlocks',
    component: () => import('@/views/admin/category/fact-admission-block/Index.vue'),
    meta: {
      pageTitle: 'Khối xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Khối xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_BLOCK,
    },
  },
  {
    path: '/category/factAdmissionRoles',
    name: 'factAdmissionRoles',
    component: () => import('@/views/admin/category/fact-admission-role/Index.vue'),
    meta: {
      pageTitle: 'Chức vụ trong hội đồng xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Chức vụ trong hội đồng xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_ROLE,
    },
  },
  {
    path: '/category/factAdmissionConducts',
    name: 'factAdmissionConducts',
    component: () => import('@/views/admin/category/fact-admission-conduct/Index.vue'),
    meta: {
      pageTitle: 'Loại hạnh kiểm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại hạnh kiểm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_CONDUCT,
    },
  },
  {
    path: '/category/factAcademicPerformances',
    name: 'factAcademicPerformances',
    component: () => import('@/views/admin/category/fact-academic-performance/Index.vue'),
    meta: {
      pageTitle: 'Loại học lực',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại học lực',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ACADEMIC_PERFORMANCE,
    },
  },
  {
    path: '/category/factAdmissionCriteriaTypes',
    name: 'factAdmissionCriteriaTypes',
    component: () => import('@/views/admin/category/fact-admission-criteria-type/Index.vue'),
    meta: {
      pageTitle: 'Loại tiêu chí xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại tiêu chí xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_CRITERIA_TYPE,
    },
  },
  {
    path: '/category/factAdmissionStudentTypes',
    name: 'factAdmissionStudentTypes',
    component: () => import('@/views/admin/category/fact-admission-student-type/Index.vue'),
    meta: {
      pageTitle: 'Loại học viên ứng tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại học viên ứng tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_STUDENT_TYPE,
    },
  },
  {
    path: '/category/factAdmissionViolateTypes',
    name: 'factAdmissionViolateTypes',
    component: () => import('@/views/admin/category/fact-admission-violate-type/Index.vue'),
    meta: {
      pageTitle: 'Loại vi phạm thi xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại vi phạm thi xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_VIOLATE_TYPE,
    },
  },
  {
    path: '/category/factAdmissionDisciplines',
    name: 'factAdmissionDisciplines',
    component: () => import('@/views/admin/category/fact-admission-discipline/Index.vue'),
    meta: {
      pageTitle: 'Hình thức xử lý vi phạm thi xét tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Hình thức xử lý vi phạm thi xét tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_DISCIPLINE,
    },
  },
  {
    path: '/category/factAdmissionCertificateTypes',
    name: 'factAdmissionCertificateTypes',
    component: () => import('@/views/admin/category/fact-admission-certificate-type/Index.vue'),
    meta: {
      pageTitle: 'Loại chứng chỉ học tập',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại chứng chỉ học tập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_CERTIFICATE_TYPE,
    },
  },
  {
    path: '/category/factAdmissionConfirmationTypes',
    name: 'factAdmissionConfirmationTypes',
    component: () => import('@/views/admin/category/fact-admission-confirmation-type/Index.vue'),
    meta: {
      pageTitle: 'Loại giấy chứng nhận',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại giấy chứng nhận',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_CONFIRMATION_TYPE,
    },
  },
  {
    path: '/category/factAdmissionLanguageCertificateTypes',
    name: 'factAdmissionLanguageCertificateTypes',
    component: () => import('@/views/admin/category/fact-admission-language-certificate-type/Index.vue'),
    meta: {
      pageTitle: 'Loại chứng chỉ ngoại ngữ',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại chứng chỉ ngoại ngữ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_LANGUAGE_CERTIFICATE_TYPE,
    },
  },
  {
    path: '/category/factPriorityZones',
    name: 'factPriorityZones',
    component: () => import('@/views/admin/category/fact-priority-zone/Index.vue'),
    meta: {
      pageTitle: 'Khu vực ưu tiên',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Khu vực ưu tiên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_PRIORITY_ZONE,
    },
  },
  {
    path: '/category/factHighSchools',
    name: 'factHighSchools',
    component: () => import('@/views/admin/category/fact-high-school/Index.vue'),
    meta: {
      pageTitle: 'Danh sách trường THPT',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách trường THPT',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_HIGH_SCHOOL,
    },
  },
  {
    path: '/category/factAdmissionScoreTypes',
    name: 'factAdmissionScoreTypes',
    component: () => import('@/views/admin/category/fact-admission-score-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại điểm',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Danh sách loại điểm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_ADMISSION_SCORE_TYPE,
    },
  },

  {
    path: '/category/graduate-dissertation',
    name: 'graduateDissertation',
    component: () => import('@/views/admin/category/graduate-dissertation/Index.vue'),
    meta: {
      pageTitle: 'Đề tài khóa luận tốt nghiệp',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Đề tài khóa luận tốt nghiệp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_DISSERTATION,
    },
  },
  {
    path: '/category/factTeacherType',
    name: 'factTeacherType',
    component: () => import('@/views/admin/category/fact-teacher-type/index.vue'),
    meta: {
      pageTitle: 'Loại giảng viên',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Loại giảng viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.FACT_TEACHER_TYPE,
    },
  },
  {
    path: '/category/professionalQualification',
    name: 'professionalQualification',
    component: () => import('@/views/admission/category/professional-qualification/Index.vue'),
    meta: {
      pageTitle: 'Trình độ chuyên môn',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Trình độ chuyên môn',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PROFESSIONAL_QUALIFICATION,
    },
  },
  {
    path: '/category/applicantType',
    name: 'applicantType',
    component: () => import('@/views/admission/category/applicant-type/Index.vue'),
    meta: {
      pageTitle: 'Đối tượng đăng ký dự tuyển',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Đối tượng đăng ký dự tuyển',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.APPLICANT_TYPE,
    },
  },
  {
    path: '/category/generalEducationLevel',
    name: 'generalEducationLevel',
    component: () => import('@/views/admission/category/general-education-level/Index.vue'),
    meta: {
      pageTitle: 'Trình độ GDPT',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Trình độ GDPT',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GENERAL_EDUCATION_LEVEL,
    },
  },
  {
    path: '/category/agencyLevel',
    name: 'agencyLevel',
    component: () => import('@/views/admission/category/agency-level/Index.vue'),
    meta: {
      pageTitle: 'Cấp cơ quan',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Cấp cơ quan',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.AGENCY_LEVEL,
    },
  },
]
