import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    totalRows: 0,
    statuses: STATUSES,
    teacherType: [],
    timeFrameResearch: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    teacherType: state => state.teacherType,
    timeFrameResearch: state => state.timeFrameResearch,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
    SET_TEACHER_TYPE: (state, teacherType) => {
      state.teacherType = teacherType
    },
    SET_TIME_FRAME_RESEARCH: (state, timeFrameResearch) => {
      state.timeFrameResearch = timeFrameResearch
    },
  },
  actions: {
    async getTeacherType({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_teacher_type/getAllActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHER_TYPE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readTimeFrameResearch({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_time_frame_research', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TIME_FRAME_RESEARCH', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTimeFrameResearch({ commit }, params) {
      try {
        const response = await axios.post('uni/tw_time_frame_research', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTimeFrameResearch({ commit }, params) {
      try {
        const response = await axios.put(`uni/tw_time_frame_research/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTimeFrameResearch({ commit }, id) {
      try {
        const response = await axios.delete(`uni/tw_time_frame_research/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
