import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/graduationProjectRegisterRound',
    name: 'graduationProjectRegisterRound',
    component: () => import('@/views/graduation-project-register-round/GraduationProjectRegisterRound.vue'),
    meta: {
      pageTitle: 'Danh sách đợt đăng ký KLTN',
      breadcrumb: [
        {
          text: 'Quản lý đợt đăng ký KLTN',
          active: false,
        },
        {
          text: 'Quản lý đợt đăng ký KLTN',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_PROJECT_REGISTER_ROUND,
    },
  },
]
